import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';


@Component({
	selector: 'app-dashboard-header',
	templateUrl: './dashboard-header.component.html',
	styleUrls: ['./dashboard-header.component.css']
})
export class DashboardHeaderComponent implements OnInit {
	signOutIcon=faSignOutAlt;
	constructor(public authService:AuthService) { 

	}

	ngOnInit(): void {

	}

}
