<app-frontend-header></app-frontend-header>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h3 class="main-title">{{category}}</h3>
      <h4 class="main-title">Графици</h4>
    </div>
  </div>
  <div class="breadcrumbs">
    <a href="/"><span>Почетна</span></a>
    <a href="/category/{{category}}"><span>{{category}}</span></a>
    <a href="/category/{{category}}/graph-list"><span>Графици</span></a>
  </div>
  <div class="row">
    <div class="col-md-9">
      <div class="row" *ngFor="let graph of graphData">
        <div class="col-md-12">
          <h4 class="title graph-title">{{graph.title}}</h4>
          <h5>График од истражувањето
            <span class="" *ngFor="let res of graph.researches">
            <span *ngIf="res.ischecked==true">
              <a href="/category/{{category}}/research-list/{{researchId}}" target="blank">{{res.name}}</a>
              </span>
            </span>
          </h5>
        </div>
        <div class="col-md-12 graph-desc" [innerHtml]="graph?.description"></div>
        <!-- open modal -->
        <div>
          <ng-template #mymodal let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Манипулација со графикот</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              Графикот може да се манипулира со клик врз параметрите прикажани во легендата. Со клик врз параметарот тој се “вклучува” или “исклучува”, односно се прикажуваат неговите вредности на графикот или пак се исклучуваат. Со тоа се овозможува полесно и прегледно воочување на разликите и промените во графиците.
              <br>
              <br>
              <h5>Промена на вид на визуелизација</h5>
              Со избор на опција од менито истите податоци можат да се видат во различен графички приказ.
            </div>
          </ng-template>
        </div>
        <!-- end modal -->
        <div class="col-md-12" style="text-align: right;">
          <button (click)="open(mymodal)" class="manual_btn grey-btn btn">Упатство за користење</button>
          <div class="info">
            <div class="graph-info grey-btn">
              <fa-icon [icon]="info"></fa-icon>
            </div>
            <div class="my_tooltip">
              <div>
                <h5>Манипулација со графикот</h5>
                Графикот може да се манипулира со клик врз параметрите прикажани во легендата. Со клик врз параметарот тој се “вклучува” или “исклучува”, односно се прикажуваат неговите вредности на графикот или пак се исклучуваат. Со тоа се овозможува полесно и прегледно воочување на разликите и промените во графиците.
                <br>
                <br>
                <h5>Промена на вид на визуелизација</h5>
                Со избор на опција од менито истите податоци можат да се видат во различен графички приказ.
              </div>
            </div>
          </div>
        </div>
        <!-- open modal 2 -->
        <div>
          <ng-template #mymodal1 let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Вгнездување на график</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              Копирај го кодот и стави го графикот на својата страница
              <textarea style="width: 100%"> <img href="{{graph.image_path}}" style="max-width:100%" alt="{{graph.title}}"/><a href="/category/{{category}}/graph-list/{{title}}">{{title}}</a></textarea>
              <p>Вака ќе изгледа: </p>
              <img [src]="graph.image_path" style="max-width:100%" alt="{{graph.title}}" />
              <a href="/category/{{category}}/graph-list/{{title}}" class="graph-img">{{title}}</a>
            </div>
          </ng-template>
        </div>
        <!-- end modal 2-->
        <!-- open modal 3-->
        <div>
          <ng-template #mymodal2 let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Побарај сирови податоци</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div>Испратете барање за добивање на податоци со пополнување следниве податоци:
              </div><br>
              <!--  ask data form -->
              <form [formGroup]="askDataForm" (ngSubmit)="sendMessage(askDataForm)">
                <div class="message">
                  <div class="form-group row">
                    <div class="col-md-12"><label for="graphTitle">Наслов/цел на пораката:</label></div>
                    <div class="col-md-12"><input type="text" class="form-control" id="messageTitle" formControlName="messageTitle" [ngClass]="{ 'is-invalid': submitted && askDataForm.controls.messageTitle.errors }" />
                      <div *ngIf="submitted && askDataForm.controls.messageTitle.errors" class="invalid-feedback">
                        <div *ngIf="askDataForm.controls.messageTitle.errors.required"> <span>Ова поле е задолжително</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12"><label for="graphTitle">Порака:</label></div>
                    <div class="col-md-12"><textarea type="text" class="form-control" id="messageDesc" formControlName="messageDes" required [ngClass]="{ 'is-invalid': submitted && askDataForm.controls.messageDes.errors }"></textarea>
                      <div *ngIf="submitted && askDataForm.controls.messageDes.errors" class="invalid-feedback">
                        <div *ngIf="askDataForm.controls.messageDes.errors.required"> <span>Ова поле е задолжително</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12"><label for="graphTitle">Ваш email:</label></div>
                    <div class="col-md-12"><input type="email" class="form-control" id="messageEmail" formControlName="messageEmail" required email [ngClass]="{ 'is-invalid': submitted && askDataForm.controls.messageEmail.errors }" />
                      <div *ngIf="submitted && askDataForm.controls.messageEmail.errors?.email" class="invalid-feedback">
                        <div *ngIf="askDataForm.controls.messageEmail.errors?.email"><span>{{'Е- адресата не е валидна'}}</span>
                        </div>
                      </div>
                      <div *ngIf="submitted && askDataForm.controls.messageEmail.errors" class="invalid-feedback">
                        <div *ngIf="askDataForm.controls.messageEmail.errors.required"> <span>Ова поле е задолжително</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="group">

                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (success)="handleSuccessRecaptcha($event)" formControlName="recaptcha" [ngClass]="{ 'is-invalid': submitted && askDataForm.controls.recaptcha.errors }">
                    </ngx-recaptcha2>
                    <div *ngIf="submitted && askDataForm.controls.recaptcha.errors" class="invalid-feedback">
                      <div *ngIf="askDataForm.controls.recaptcha.errors.required">
                        <span>Ова поле е задолжително</span></div>
                    </div>
                  </div>
                </div>
                <div style="float: right;">
                  <button type="submit" class="btn btn-primary">Прати</button></div>
              </form>
              <!-- end form ask data -->
            </div>
          </ng-template>
        </div>
        <!-- end modal 3-->
        <div class="col-md-12">
          <highcharts-chart *ngIf="chartOptions" [Highcharts]="Highcharts" [options]="chartOptions" [(update)]="updateFlag" [oneToOne]="oneToOneFlag" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
          <div class=" col-md-12 graph_footer">
            <div class="row" style='justify-content: center;'>
              <div class="col-md-4" *ngIf="combined_chart">
                <div class="selectdiv">
                  <select class='btn my_select form-select form-select-lg' [(ngModel)]="selectedType" (change)='checkType($event)'>
                    <option class='option' *ngFor='let type of chart_type_all' [value]="type.id">{{type.name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="embeded_graph" (click)="open(mymodal1)">
                  <fa-icon [icon]="embed"></fa-icon> Вглезди го графикот
                </div>
              </div>
              <div class="col-md-4">
                <div class="row_data" (click)="open(mymodal2)">
                  <fa-icon [icon]="chat"></fa-icon>Побарај сирови податоци
                </div>
              </div>
              <div class="col-md-4" style="text-align: center;">
                <share-buttons theme="circles-dark" [include]="['copy', 'facebook', 'email', 'twitter']" [showIcon]="true" [showText]="false" url="https://rodovrector.mk/category/{{category}}/graph-list/{{title}}" twitterAccount="rodrigokamada" class="pt-5">
                </share-buttons>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6"><a href="/category/{{category}}/graph-list" class="more_links grey-btn btn"> « Назад кон графиците од истата тема</a>
            </div>
            <div class="col-md-6" style="text-align: right;"><a href="/graph-all" class="more_links grey-btn btn">Назад кон сите графици » </a></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <app-frontend-sidebar [category]="category" [selectType]="selectType"></app-frontend-sidebar>
    </div>
  </div>
</div>
<app-frontend-footer></app-frontend-footer>
