<div class="sidebar">
<ul style="padding: 0">
        <li id="politics" (click)="toggleSidebar('politics')" #toggles data-id="politics">
          <div class="sidebar_img sidebar_img_trans" id="hpol" [ngClass]="{'open_opacity': category=='Родот и политиката'}"></div>
          <div class="sidebar_img" id="pol"></div>
          <div class="parent" [ngClass]="{'open_color': category=='Родот и политиката'}">
          <div >Политика</div>
          </div>
          <ul id="child_politics" [ngClass]="{'open_sidebar': category=='Родот и политиката'|| id=='politics' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Родот и политиката'}">
            <a routerLink="/category/Родот и политиката/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Родот и политиката'}">
            <a routerLink="/category/Родот и политиката/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Родот и политиката'}">
            <a routerLink="/category/Родот и политиката/infographic-list">Инфографици</a></li>
          </ul>
        </li>
        <li id="family" (click)="toggleSidebar('family')" #toggles data-id="family">
          <div class="sidebar_img sidebar_img_trans" id="hfam" [ngClass]="{'open_opacity': category=='Родот и семејството'}"></div>
          <div class="sidebar_img" id="fam"></div>
          <div class="parent" [ngClass]="{'open_color': category=='Родот и семејството'}">
          <div>Семејство</div>
          </div>
          <ul id="child_family" [ngClass]="{'open_sidebar': category=='Родот и семејството'|| id=='family' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања'&& category=='Родот и семејството'}">
            <a routerLink="/category/Родот и семејството/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Родот и семејството'}">
            <a routerLink="/category/Родот и семејството/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Родот и семејството'}">
            <a routerLink="/category/Родот и семејството/infographic-list">Инфографици</a></li>
          </ul>
        </li>
        <li id="economy" (click)="toggleSidebar('economy')" #toggles data-id="economy">
          <div class="sidebar_img sidebar_img_trans" id="heco" [ngClass]="{'open_opacity': category=='Економија'}"></div>
          <div class="sidebar_img" id="eco"></div>
          <div class="parent">
          <div>Економија</div></div>
          <ul id="child_economy" [ngClass]="{'open_sidebar': category=='Економија'|| id=='economy' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Економија'}">
            <a routerLink="/category/Економија/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Економија'}">
            <a routerLink="/category/Економија/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Економија'}">
            <a routerLink="/category/Економија/infographic-list">Инфографици</a></li>
          </ul>
        </li>
        <li id="youth" (click)="toggleSidebar('youth')" #toggles data-id="youth">
          <div class="sidebar_img sidebar_img_trans" id="hyou" [ngClass]="{'open_opacity': category=='Млади'}"></div>
          <div class="sidebar_img" id="you"></div>
          <div class="parent">
          <div>Млади</div></div>
          <ul id="child_youth" [ngClass]="{'open_sidebar': category=='Млади'|| id=='youth' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Млади'}">
            <a routerLink="/category/Млади/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Млади'}">
            <a routerLink="/category/Млади/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Млади'}">
            <a routerLink="/category/Млади/infographic-list">Инфографици</a></li>
          </ul>
        </li>
        <li id="urban_development" (click)="toggleSidebar('urban_development')" #toggles data-id="urban_development">
          <div class="sidebar_img sidebar_img_trans" id="hurb" [ngClass]="{'open_opacity': category=='Урбано планирање'}"></div>
          <div class="sidebar_img" id="urb"></div>
          <div class="parent"><div>Урбано планирање</div></div>
          <ul id="child_urban_development" [ngClass]="{'open_sidebar': category=='Урбано планирање'|| id=='urban_development' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Урбано планирање'}">
            <a routerLink="/category/Урбано планирање/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Урбано планирање'}">
            <a routerLink="/category/Урбано планирање/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Урбано планирање'}">
            <a routerLink="/category/Урбано планирање/infographic-list">Инфографици</a></li>
          </ul>
        </li>
         <li id="health" (click)="toggleSidebar('health')" #toggles data-id="health">
          <div class="sidebar_img sidebar_img_trans" id="hheal" [ngClass]="{'open_opacity': category=='Здравје'}"></div>
          <div class="sidebar_img" id="heal"></div>
          <div class="parent"><div>Здравје</div></div>
          <ul id="child_health" [ngClass]="{'open_sidebar': category=='Здравје'|| id=='health' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Здравје'}">
            <a routerLink="/category/Здравје/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Здравје'}">
            <a routerLink="/category/Здравје/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Здравје'}">
            <a routerLink="/category/Здравје/infographic-list">Инфографици</a></li>
          </ul>
        </li>
         <li id="education" (click)="toggleSidebar('education')" #toggles data-id="education">
          <div class="sidebar_img sidebar_img_trans" id="hedu" [ngClass]="{'open_opacity': category=='Образование'}"></div>
          <div class="sidebar_img" id="edu"></div>
          <div class="parent"><div>Образование</div></div>
          <ul id="child_education" [ngClass]="{'open_sidebar': category=='Образование'|| id=='education' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Образование'}">
            <a routerLink="/category/Образование/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Образование'}">
            <a routerLink="/category/Образование/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Образование'}">
            <a routerLink="/category/Образование/infographic-list">Инфографици</a></li>
          </ul>
        </li>
         <li id="wellfare" (click)="toggleSidebar('wellfare')" #toggles data-id="wellfare">
          <div class="sidebar_img sidebar_img_trans" id="hwell" [ngClass]="{'open_opacity': category=='Социјална Родот и политиката'}"></div>
          <div class="sidebar_img" id="well"></div>
          <div class="parent"><div>Социјална Родот и политиката</div></div>
          <ul id="child_wellfare" [ngClass]="{'open_sidebar': category=='Социјална Родот и политиката'|| id=='wellfare' && toggle==true }">
           <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Социјална Родот и политиката'}">
            <a routerLink="/category/Социјална Родот и политиката/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Социјална Родот и политиката'}">
            <a routerLink="/category/Социјална Родот и политиката/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Социјална Родот и политиката'}">
            <a routerLink="/category/Социјална Родот и политиката/infographic-list">Инфографици</a></li>
          </ul>
        </li>
         <li id="other" (click)="toggleSidebar('other')" #toggles data-id="other">
          <div class="sidebar_img sidebar_img_trans" id="hoth" [ngClass]="{'open_opacity': category=='Останати'}"></div>
          <div class="sidebar_img" id="oth"></div>
          <div class="parent"><div>Останати</div></div>
          <ul id="child_other" [ngClass]="{'open_sidebar': category=='Останати'|| id=='other' && toggle==true }">
             <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Останати'}">
            <a routerLink="/category/Останати/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Останати'}">
            <a routerLink="/category/Останати/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Останати'}">
            <a routerLink="/category/Останати/infographic-list">Инфографици</a></li>
          </ul>
        </li>
      </ul>
      </div>