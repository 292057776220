<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
        <span class="nav-inside"><a href="/dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
        <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>
<div class="container">
<div class="row">
<div class="col-md-12 form-group searchBox">
 	<input type="text" [(ngModel)]="searchBox" class="form-control" style="display: inline;width: 89%" placeholder=" ... Пребарај ги графиците ... " />
<span (click)="search(searchBox)" class="btn btn-primary">Пребарај </span> 
</div>
</div>
	<div class="row">
	<div class="col-md-8 dashboard_title">Графици - Инфографици</div>
		<div class="col-md-4" style="text-align: right;padding: 20px 0">
			<a href="/graph-add" class="btn btn-primary">Додади нов график или инфографик</a>
		</div>
	</div>
	<div class="list_grid list_grid_title">
		<div class="row">
			<div class="col-md-6">Листа на графици и инфографици</div>
			<div class="col-md-2" ><fa-icon [icon]="chartIcon"></fa-icon></div>

			<div class="col-md-2" ><fa-icon [icon]="editIcon"></fa-icon></div>
			<div class="col-md-2"><fa-icon [icon]="trashIcon"></fa-icon></div>
		</div>
	</div>
	<div *ngFor="let graph of graphs" class="list_grid">
		<div class="row">
			<div class="col-md-6"><a  routerLink="{{graph.id}}">{{graph.title}}</a></div>
			<div class="col-md-2"><a  routerLink="{{graph.id}}">{{graph.graphic_type_view}}</a></div>
			<div class="col-md-2"><a routerLink="{{graph.id}}">Промени</a></div>
			<div class="col-md-2 del"><span  (click)="deleteGraph(graph.id)">Избриши</span></div>
		</div>
	</div>
	 <div class="row prev_next">
    <div class="col-md-6" >
    <button class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButton" (click)="prevGraphPage()">Претходна страница
    </button>
    </div>
    <div class="col-md-6" >
    <button class="btn btn-primary btn-sm float-right" (click)="nextGraphPage()" [disabled]="disableNextButton">Следна страница
    </button>
    </div>
  </div>
</div>