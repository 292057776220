<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
    <span class="nav-inside"><a href="/dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
        <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>
<form [formGroup]="graphForm" (ngSubmit)="onSubmit(graphForm)">
  <div class="container">
    <div class="dashboard_title">Додади нов график или инфографик</div>
    <div class="form-group row">
      <div class="col-md-3"><label for="graphTitle">Име</label></div>
      <div class="col-md-9"><input type="text" class="form-control" id="graphTitle" formControlName="title" required [ngClass]="{ 'is-invalid': submitted && graphForm.controls.title.errors }" />
        <div *ngIf="submitted && graphForm.controls.title.errors" class="invalid-feedback">
          <div *ngIf="graphForm.controls.title.errors.required"><span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="description">Опис</label></div>
      <div class="col-md-9">
        <angular-editor [placeholder]="'Внесете опис'" formControlName="description" required [config]="config" [ngClass]="{ 'is-invalid': submitted && graphForm.controls.description.errors }">
        </angular-editor>
        <div *ngIf="submitted && graphForm.controls.description.errors" class="invalid-feedback">
          <div *ngIf="graphForm.controls.description.errors.required"><span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="graphResearchesRes">Истражувања</label></div>
      <div class="col-md-8">
        <div class="category_box">
          <div *ngFor="let res of researches; let index=index; ">
            <input [id]="res.title" type="checkbox" name="researches" [value]="res.title"  (change)="isResearchSelected($event,res.title)" [ngClass]="{ 'is-invalid': submitted && graphForm.controls.researches.errors }">
           <label [for]="res.title" style="width: 80%;margin-left: 5px;line-height: 1;"> {{res.title}}</label>
          </div>
        </div>
        <div *ngIf="submitted && !checkedCat" class=" mandatory_line">
          <div *ngIf="submitted && !checkedCat">
            <span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-1"><span class="chart_add" placement="top" ngbTooltip="Додади категорија" (click)="addResearch()">+</span></div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="graphResearchesCat">Категории</label></div>
      <div class="col-md-8">
        <div class="category_box">
          <div *ngFor="let category of categories">
            <input [id]="category.name" type="checkbox" name="categories" [value]="category.name" (change)="isCategorySelected($event,category.name)" [ngClass]="{ 'is-invalid': submitted && graphForm.controls.categories.errors }">
            <label [for]="category.name" style="width: 80%;margin-left: 5px;line-height: 1;">{{category.name}}</label>
          </div>
        </div>
        <div *ngIf="submitted && !checkedCat" class=" mandatory_line">
          <div *ngIf="submitted && !checkedCat">
            <span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-1"><span class="chart_add" placement="top" ngbTooltip="Додади категорија" (click)="addCategory()">+</span></div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="graphSource">Извор</label></div>
      <div class="col-md-9"><input type="text" class="form-control" id="graphSource" formControlName="source" required [ngClass]="{ 'is-invalid': submitted && graphForm.controls.source.errors }" />
        <div *ngIf="submitted && graphForm.controls.source.errors" class="invalid-feedback">
          <div *ngIf="graphForm.controls.source.errors.required"><span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="graphSourceLink">Линк до изворот</label></div>
      <div class="col-md-9"><input type="text" class="form-control" id="graphSourceLink" formControlName="source_link" required [ngClass]="{ 'is-invalid': submitted && graphForm.controls.source_link.errors }" />
        <div *ngIf="submitted && graphForm.controls.source_link.errors" class="invalid-feedback">
          <div *ngIf="graphForm.controls.source_link.errors.required"><span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="!showInfographic">
      <div class="col-md-3">
        <label for="myFile">Слика за графикот (.png /.jpg)</label>
      </div>
      <div class="col-md-4" *ngIf="image_path">
        <img [src]="image_path" width="230px" height="150px" />
        <p *ngIf="uploadImageSuccessful==1" class="respo succ">Upload successfull</p> <br />
        <p *ngIf="uploadImageSuccessful==0" class="respo fail">Upload not completed, try again</p> <br />
      </div>
      <div class="col-md-5">
        <input type="file" FormControl="file" (change)="fileEvent($event)" />
      </div>
    </div>
    <div class="form_group row">
      <div class="col-md-3">Избери приказ на податок</div>
      <div class="col-md-9">
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="graphic_type_view" value="график" id="graphic" (change)="graphicInfographic($event)" checked=true />
          <label class="form-check-label" for="graph">
            График - (приказ на податоци со график)
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="graphic_type_view" value="инфографик" id="infographic" (click)="graphicInfographic($event)" />
          <label class="form-check-label" for="infographic">
            Инфографик - (приказ на податоци со инфографик)
          </label>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showInfographic">
    <div class="container_fluid" style="background: #ced3e7;padding: 10px 0px; margin:20px 0px">
      <div class="container">Креирај визуелизација на графикот
      </div>
    </div>
    <div class="container" style="padding: 30px 15px">
      <div class="form_group row">
        <div class="col-md-3">Избери тип на график</div>
        <div class="col-md-9">
          <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="chart_type" value="chart_type_frequency" id="chart_type_frequency" (change)="showType($event)" checked=true />
            <label class="form-check-label" for="chart_type_frequency">
              column, bar, line - (приказ на податоци во проценти или фрекфенција)
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="chart_type" value="chart_type_percent" id="chart_type_percent" (click)="showType($event)" />
            <label class="form-check-label" for="chart_type_percent">
              combination series or pie - (комбинација на графици или пита)
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="form-group row">
        <div class="col-md-3"><label for="chartTitle">Наслов</label></div>
        <div class="col-md-9"><input type="text" class="form-control" id="chartTitle" formControlName="chart_title" />
          <!-- <div *ngIf="submitted && graphForm.controls.chart_title.errors" class="invalid-feedback">
          <div *ngIf="graphForm.controls.chart_title.errors.required"><span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div> -->
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-3"><label for="chartSubtitle">Година на објавени податоци (subtitle)</label></div>
        <div class="col-md-9"><input type="text" class="form-control" id="chartSubtitle" formControlName="chart_subtitle" />
          <span class="chart_help"> ( пр: 2010 или 2010 - 2015 )</span>
        </div>
      </div>
      <!-- check if is pie or frequency -->
      <div class="form-group row" *ngIf=" serie_type!='pie'">
        <div class="col-md-3"><label for="chartXaxis">Хоризонтални категории (x-Axis)</label></div>
        <div class="col-md-9"><input type="text" class="form-control" id="chartXaxis" formControlName="chart_xaxis" />
          <span class="chart_help"> ( пр: по години = 2000,2001,2002, по возраст = 15-24,25-34,35-44, во полето внесувате само броеви)</span></div>
      </div>
      <div class="form-group row">
        <div class="col-md-3"><label for="chartYaxis">Вертикално (y-Axis)</label></div>
        <div class="col-md-9"> <input type="text" class="form-control" id="chartYaxis" formControlName="chart_yaxis" />
          <span class="chart_help"> ( пр: проценти или број или часови и минути )</span>
        </div>
      </div>
      <div class="form-group " *ngIf="showFrequency">
        <div class="row" style="padding:10px 0px; align-items: center;">
          <div class="col-md-7"></div>
          <div class="col-md-4" style="text-align: right;">Додади серија - визуелизација </div>
          <div class="col-md-1"><span class="chart_add" (click)="addSeries()">+</span></div>
        </div>
        <div formArrayName="chart_series" class="">
          <div *ngFor="let chart_seriesForm of chart_series().controls; let index='index'">
            <div [formGroupName]="index">
              <div class="form-group row">
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-4">
                      <div style="color: white;background-color: #585e87;text-align: center;">{{index+1}} </div>
                    </div>
                    <div class="col-md-7"><label for="cat_name"> Категорија :</label></div>
                  </div>
                </div>
                <div class="col-md-9"><input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && chart_series().controls[index].get('name')?.errors }" />
                  <div *ngIf="submitted && chart_series().controls[index].get('name')?.errors?.required" class="invalid-feedback mandatory_line">
                    <div *ngIf="chart_series().controls[index].get('name')?.errors?.required"><span>{{'Ова поле е задолжително'}}</span>
                    </div>
                  </div>
                  <span class="chart_help">(пр: жени)</span>
                </div>
              </div>
              <div class="form-group row" *ngIf="show_stack">
                <div class="col-md-3"><label for="cat_data">Групиран stack column :</label></div>
                <div class="col-md-9"><input type="text" formControlName="stack" class="form-control" [ngClass]="{ 'is-invalid': submitted && chart_series().controls[index].get('data')?.errors }" />
                  <div *ngIf="submitted && chart_series().controls[index].get('stack')?.errors?.required" class="invalid-feedback mandatory_line">
                    <div *ngIf="chart_series().controls[index].get('stack')?.errors?.required"><span>{{'Ова поле е задолжително'}}</span>
                    </div>
                  </div>
                  <span class="chart_help">( пр:  жени ) </span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-3"><label for="cat_data">Податоци :</label></div>
                <div class="col-md-9"><input type="text" formControlName="data" class="form-control" [ngClass]="{ 'is-invalid': submitted && chart_series().controls[index].get('data')?.errors }" />
                  <div *ngIf="submitted && chart_series().controls[index].get('data')?.errors?.required" class="invalid-feedback mandatory_line">
                    <div *ngIf="chart_series().controls[index].get('data')?.errors?.required"><span>{{'Ова поле е задолжително'}}</span>
                    </div>
                  </div>
                  <span class="chart_help">( пр: 25,55,66 - овој број на категории е еднаков на бројот на x-Axis ) </span>
                </div>
              </div>

              
              <div class="form-group row">
                <div class="col-md-3"><label for="cat_color">Боја на бар :</label></div>
                <div class="col-md-8"><input type="text" formControlName="color" class="form-control" [ngClass]="{ 'is-invalid': submitted && chart_series().controls[index].get('color')?.errors }" />
                  <div *ngIf="submitted && chart_series().controls[index].get('color')?.errors?.required" class="invalid-feedback mandatory_line">
                    <div *ngIf="chart_series().controls[index].get('color')?.errors?.required"><span>{{'Ова поле е задолжително'}}</span>
                    </div>
                  </div>
                  <span class="chart_help">( пр: #58D68D )</span>
                </div>
                <div class="col-md-1"><span class="chart_add" placement="top" ngbTooltip="Избриши ја серијата" (click)="removeSeries(index)">-</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- new  -->
      <div class="row series" style="align-items: center" *ngIf="showPercent">
        <div class="col-md-10" style="padding: 20px 0px;text-align: right;">Одберете визуализација </div>
        <div class="col-md-2" style="padding-left: 20px;padding-right: 0">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="serie_type" id="serie_type1" value="column" name="serie_type" (change)="changeChart($event)" checked />
            <label class="form-check-label" for="serie_type1">
              Колона, лента, линија
            </label>
          </div>
          <div class="form-check" *ngIf="showPercent">
            <input class="form-check-input" type="radio" name="serie_type" id="serie_type4" value="pie" name="serie_type" (change)="changeChart($event)" />
            <label class="form-check-label" for="serie_type4">
              Пита
            </label>
          </div>
        </div>
      </div>
      <!--  -->
      <!-- anotehr way  column_bar_line-->
      <div formArrayName="chart_series_combined" *ngIf="showPercent && serie_type!='pie'">
        <div *ngFor="let serie of chart_series_combined().controls; let serieIndex=index ">
          <div [formGroupName]="serieIndex">
            <div class="col-md-12" style="padding: 30px 0px">Тука можеш да составиш колона (column) и линија (line), лента (bar) и линија (line), линијата може да биде со пр, броеви за средна вредност или нормална вредност.<br> Внимавајте да не ставите лента (bar) и колона (column) зашто ќе дојде до преклопување на графиците ( едниот нема да се гледа ).</div>
            <div class="row" *ngIf="secondClick">
              <div class="col-md-11" style="padding: 20px 0px;text-align: right;">Додади серија на визуелизација</div>
              <div class="col-md-1"><span class="chart_add" (click)="addNewColumnBarLine(serieIndex)">+</span></div>
            </div>
            <div formArrayName="column_bar_line">
              <div *ngFor="let data of getColumnBarLine(serieIndex).controls; let dataIndex=index ">
                <div [formGroupName]="dataIndex">
                  <div class="form-group row">
                    <div class="row" *ngIf="serie_type!='pie'">
                      <div class="col-md-3">
                        <div class="row">
                          <div class="col-md-3">
                            <div style="color: white;background-color: #585e87;text-align: center;">{{dataIndex+1}} </div>
                          </div>
                          <div class="col-md-9"><label for="cat_name"> Име на категорија:</label></div>
                        </div>
                      </div>
                      <div class="col-md-9"><input type="text" formControlName="name" class="form-control" /><span class="chart_help">(пр: жени)</span> </div>
                      <div class="col-md-3"><label for="cat_data">Податоци :</label></div>
                      <div class="col-md-9"><input type="text" formControlName="data" class="form-control" />
                        <span class="chart_help">( пр: 25,55,66 - овој број на категории е еднаков на бројот на x-Axis ) </span>
                      </div>
                      <div class="col-md-3"><label for="cat_data">Боја :</label></div>
                      <div class="col-md-9"><input type="text" formControlName="color" class="form-control" />
                        <span class="chart_help">( пр: #58D68D ) </span>
                      </div>
                      <div class="col-md-3"><label for="cat_type">Тип :</label></div>
                      <div class="col-md-8"><input type="text" formControlName="type" class="form-control" />
                        <span class="chart_help">( пр: column or line or bar ) </span>
                      </div>
                      <div class="col-md-1"><span class="chart_add" placement="top" ngbTooltip="Избриши ја серијата" (click)="removeColumnBarLine(serieIndex,dataIndex)">-</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <!-- another way end  column_bar_line-->
      <!--  -->
      <!-- another way pie -->
      <div formArrayName="chart_series_combined" *ngIf="showPercent && serie_type=='pie'">
        <div *ngFor="let serie of chart_series_combined().controls; let serieIndex=index ">
          <div [formGroupName]="serieIndex">
            <div formArrayName="pie">
              <div *ngFor="let pie of getPie(serieIndex).controls; let dataIndex=index ">
                <div [formGroupName]="dataIndex">
                  <div class="row">
                    <div class="col-md-11" style="padding: 20px 0px;text-align: right;">Додади серија на визуелизација, состави пита од 100% </div>
                    <div class="col-md-1">
                      <span class="chart_add" (click)="addNewPieDataInData(serieIndex, dataIndex)">+</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-4">
                      <div class="row">
                        <div class="col-md-3">
                          <div style="color: white;background-color: #585e87;text-align: center;">{{dataIndex+1}} </div>
                        </div>
                        <div class="col-md-9"><label for="cat_name"> Име на категорија:</label></div>
                      </div>
                    </div>
                    <div class="col-md-7"><input type="text" formControlName="name" class="form-control" /></div>
                  </div>
                  <div formArrayName="data">
                    <div *ngFor="let dat of getPieDataInData(serieIndex,dataIndex).controls; let datIndex=index ">
                      <div [formGroupName]="datIndex">
                        <div class="form-group row">
                          <div class="col-md-4"><label for="cat_name">Име:</label></div>
                          <div class="col-md-7"><input type="text" formControlName="name" class="form-control" /></div>
                        </div>
                        <div class="form-group row">
                          <div class="col-md-4"><label for="cat_data">Процент (за да прикаже %) :</label></div>
                          <div class="col-md-7"><input type="number" formControlName="y" class="form-control" style="width:200px" /><span class="chart_help"> ( пр: 52.33 или 30 ) </span></div>
                        </div>
                        <div class="form-group row">
                          <div class="col-md-4"><label for="cat_color">Боја на бар :</label></div>
                          <div class="col-md-7"><input type="text" formControlName="color" class="form-control" style="width:200px" /><span class="chart_help">( пр: #58D68D )</span></div>
                          <div class="col-md-1"><span class="chart_add" placement="top" ngbTooltip="Избриши ја серијата" (click)="removePieDatainData(serieIndex,dataIndex,datIndex)">-</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="buttonNewPie">
                  <div class="col-md-11" style="padding: 20px 0px;text-align: right;">
                    Додади нова пита
                  </div>
                  <div class="col-md-1">
                    <span class="chart_add" (click)="addPieData(serieIndex,dataIndex)">+</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <!--  -->
        </div>
      </div>
      <div></div>
      <!-- end another way pie -->
      <div class="form-group row" *ngIf="showFrequency">
        <div class="col-md-3"><label for="primaryChartType">Одберете примарна визуелизација на графикот</label></div>
        <div class="col-md-9">
          <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="chart_primary_type" id="chart_type_primary1" value="column">
            <label class="form-check-label" for="chart_type_primary1">
              Колона
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="chart_primary_type" id="chart_type_primary2" value="line">
            <label class="form-check-label" for="chart_type_primary2">
              Линија
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="chart_primary_type" id="chart_type_primary3" value="bar">
            <label class="form-check-label" for="chart_type_primary3">
              Лента
            </label>
          </div>
          <div class="form-check" *ngIf="showPercent">
            <input class="form-check-input" type="radio" formControlName="chart_primary_type" id="chart_type_primary4" value="pie">
            <label class="form-check-label" for="chart_type_primary4">
              Пита
            </label>
          </div>
          <div *ngIf="submitted && graphForm.controls.chart_primary_type.errors" class="mandatory_line">
            <div *ngIf="graphForm.controls.chart_primary_type.errors.required"><span>{{'Ова поле е задолжително'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row" *ngIf="showFrequency">
        <div class="col-md-3"><label for="primaryChartType">Повеќеслојност</label></div>
        <div class="col-md-9">
          <div class="form-check">
            <input class="form-check-input" type="radio" value=undefined formControlName="chart_stack" id="chart_stack1" (change)="test($event)">
            <label class="form-check-label" for="chart_stack1" checked=true >
              Нормален график
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="normal" formControlName="chart_stack" id="chart_stack2"  (change)="test($event)" >
            <label class="form-check-label" for="chart_stack2">
              Повеќеслоен
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="percent" formControlName="chart_stack" id="chart_stack3" (change)="test($event)">
            <label class="form-check-label" for="chart_stack3" >
              Повеќеслоен, скалирано од 100%
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" style="margin-bottom: 50px">
    <button type="submit" class="btn btn-primary">Додадете</button>
  </div>
</form>
