import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';


import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DashboardResearcherAddComponent } from './components/dashboard-researcher-add/dashboard-researcher-add.component';
import { AuthService } from "./shared/services/auth.service";

import { ResearcherService} from "./shared/services/researcher.service";
import { DashboardResearcherListComponent } from './components/dashboard-researcher-list/dashboard-researcher-list.component';
import { DashboardResearcherUpdateComponent } from './components/dashboard-researcher-update/dashboard-researcher-update.component';
import { DashboardCategoryAddComponent } from './components/dashboard-category-add/dashboard-category-add.component';
import { DashboardCategoryListComponent } from './components/dashboard-category-list/dashboard-category-list.component';
import { DashboardCategoryUpdateComponent } from './components/dashboard-category-update/dashboard-category-update.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { GraphService} from "./shared/services/graph.service";
import { DashboardGraphAddComponent } from './components/dashboard-graph-add/dashboard-graph-add.component';
import { DashboardGraphListComponent } from './components/dashboard-graph-list/dashboard-graph-list.component';
import { DashboardGraphUpdateComponent } from './components/dashboard-graph-update/dashboard-graph-update.component';

import { ResearchService} from "./shared/services/research.service";

import { DashboardResearchAddComponent } from './components/dashboard-research-add/dashboard-research-add.component';
import { DashboardResearchListComponent } from './components/dashboard-research-list/dashboard-research-list.component';
import { DashboardResearchUpdateComponent } from './components/dashboard-research-update/dashboard-research-update.component';
import { DashboardPageComponent } from './components/dashboard-page/dashboard-page.component';
import { FrontendHeaderComponent } from './components/frontend-header/frontend-header.component';
import { FrontendFooterComponent } from './components/frontend-footer/frontend-footer.component';
import { FrontendHomeComponent } from './components/frontend-home/frontend-home.component';
import { FrontendCategoryPageComponent } from './components/frontend-category-page/frontend-category-page.component';
import { FrontendResearchListComponent } from './components/frontend-research-list/frontend-research-list.component';
import { FrontendResearchPageComponent } from './components/frontend-research-page/frontend-research-page.component';
import { FrontendGraphListComponent } from './components/frontend-graph-list/frontend-graph-list.component';
import { FrontendGraphPageComponent } from './components/frontend-graph-page/frontend-graph-page.component';

import { HighchartsChartModule } from 'highcharts-angular';
import { FrontendSidebarComponent } from './components/frontend-sidebar/frontend-sidebar.component';
import { FrontendInfographicListComponent } from './components/frontend-infographic-list/frontend-infographic-list.component';
import { FrontendInfographicPageComponent } from './components/frontend-infographic-page/frontend-infographic-page.component';
import { FrontendPageComponent } from './components/frontend-page/frontend-page.component';
import { FrontendGraphAllComponent } from './components/frontend-graph-all/frontend-graph-all.component';
import { FrontendResearchAllComponent } from './components/frontend-research-all/frontend-research-all.component';
import { FrontendInfographicAllComponent } from './components/frontend-infographic-all/frontend-infographic-all.component';
import { FrontendSearchComponent } from './components/frontend-search/frontend-search.component';


import { NgxCaptchaModule } from 'ngx-captcha';


// social buttons

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
@NgModule({
	declarations: [
	AppComponent,
	SignInComponent,
	DashboardComponent,
	DashboardHeaderComponent,
	DashboardResearcherAddComponent,
	DashboardResearcherListComponent,
	DashboardResearcherUpdateComponent,
	DashboardCategoryAddComponent,
	DashboardCategoryListComponent,
	DashboardCategoryUpdateComponent,
	DashboardGraphAddComponent,
	DashboardGraphListComponent,
	DashboardGraphUpdateComponent,
	DashboardResearchAddComponent,
	DashboardResearchListComponent,
	DashboardResearchUpdateComponent,
	DashboardPageComponent,
	FrontendHeaderComponent,
	FrontendFooterComponent,
	FrontendHomeComponent,
	FrontendCategoryPageComponent,
	FrontendResearchListComponent,
	FrontendResearchPageComponent,
	FrontendGraphListComponent,
	FrontendGraphPageComponent,
	FrontendSidebarComponent,
	FrontendInfographicListComponent,
	FrontendInfographicPageComponent,
	FrontendPageComponent,
	FrontendGraphAllComponent,
	FrontendResearchAllComponent,
	FrontendInfographicAllComponent,
	FrontendSearchComponent,
	

	],
	imports: [
	BrowserModule,
	AppRoutingModule,
	FormsModule,
	ReactiveFormsModule,
	AngularFireModule.initializeApp(environment.firebase),
	AngularFireAuthModule,
	AngularFirestoreModule,
	AngularFireStorageModule,
	NgbModule,
	FontAwesomeModule,
	HttpClientModule, 
	AngularEditorModule,
	HighchartsChartModule,
	NgxCaptchaModule,

	ShareButtonsModule,
	ShareIconsModule
	],
	providers: [AuthService, NgbActiveModal, Meta],
	bootstrap: [AppComponent]
})
export class AppModule { }
