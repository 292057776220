<app-frontend-header></app-frontend-header>
<div class="container search">
  <div class="row">
    <div class="col-md-12">
      <h3 class="main-title">Пребарувај</h3>
      <h4 class="main-title">Тука</h4>
    </div>
  </div>
  <div class="breadcrumbs">
    <a href="/"><span>Почетна</span></a>
  </div>
  <div class="row">
    <div class="col-md-9">
    <div id="search_research">
      <div>
        <h3 class="search_part">Пребарување во истражувања:</h3>
      </div>
      <div *ngIf="emptyResearch">Во категоријата не постои такво истражување</div>
      <div class="row" *ngFor="let research of researchList; let i=index ">
        <div class="col-md-9">
          <div>
            <h4><a href="/category/{{research.categories[i]?.name}}/research-list/{{research.title}}">{{research?.title}}</a></h4>
          </div>
          <span [innerHtml]="research?.abstract.slice(0,300)"> </span><span class="more"><a href="/category/{{research.categories[i]?.name}}/research-list/{{research.title}}">...повеќе</a></span>
        </div>
      </div>
       <div class="row">
    <div class="col-md-6"><button class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButtonResearch" (click)="prevResearchPage()">Претходна страница</button></div>
    <div class="col-md-6"><button class="btn btn-primary btn-sm float-right" (click)="nextResearchPage()" [disabled]="disableNextButtonResearch">Следна страница</button></div>
  </div>
  </div>
  <div id="search_graph">
      <div>
        <h3 class="search_part">Пребарување во графици:</h3>
      </div>
      <div *ngIf="emptyGraph">Во категоријата не постои таков график</div>
      <div class="row" *ngFor="let graph of graphList; let i=index ">
        <div class="col-md-9">
          <div>
            <h4><a href="/category/{{graph.categories[i]?.name}}/graph-list/{{graph?.id}}">{{graph?.title}}</a></h4>
          </div>
          <span [innerHtml]="graph?.description.slice(0,100)"> </span><span class="more"><a href="/category/{{graph.categories[i]?.name}}/graph-list/{{graph?.id}}">...повеќе</a></span>
        </div>
      </div>
       <div class="row">
    <div class="col-md-6"><button class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButtonGraph" (click)="prevGraphPage()">Претходна страница</button></div>
    <div class="col-md-6"><button class="btn btn-primary btn-sm float-right" (click)="nextGraphPage()" [disabled]="disableNextButtonGraph">Следна страница</button></div>
  </div>
  </div>
  <div class="search_infographic">
      <div>
        <h3 class="search_part">Пребарување во инфографици:</h3>
      </div>
      <div *ngIf="emptyInfograph">Во категоријата не постои таков инфографик</div>
      <div class="row" *ngFor="let graph of infographList; let i=index ">
        <div class="col-md-9">
          <div>
            <h4><a href="/category/{{graph.categories[i]?.name}}/graph-list/{{graph?.id}}">{{graph?.title}}</a></h4>
          </div>
          <span [innerHtml]="graph?.description.slice(0,100)"> </span><span class="more"><a href="/category/{{graph.categories[i]?.name}}/graph-list/{{graph?.id}}">...повеќе</a></span>
        </div>
      </div>
       <div class="row">
    <div class="col-md-6"><button class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButtonInfograph" (click)="prevInfographPage()">Претходна страница</button></div>
    <div class="col-md-6"><button class="btn btn-primary btn-sm float-right" (click)="nextInfographPage()" [disabled]="disableNextButtonInfograph">Следна страница</button></div>
  </div>
  </div>
    </div>
    <div class="col-md-3">
      <app-frontend-sidebar></app-frontend-sidebar>
    </div>
  </div>
</div>
<app-frontend-footer></app-frontend-footer>
