<app-frontend-header></app-frontend-header>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h3 class="main-title">{{category}}</h3>
      <h4 class="main-title">Инфографици</h4>
    </div>
  </div>
  <div class="breadcrumbs">
    <a href="/"><span>Почетна</span></a>
    <a href="/category/{{category}}"><span>{{category}}</span></a>
    <a href="/category/{{category}}/infographic-list"><span>Инфографици</span></a>
  </div>
  <div class="row">
    <div class="col-md-9">
      <div class="row" *ngFor="let graph of graphData">
        <div class="col-md-12">
          <h4 class="title graph-title">{{graph.title}}</h4>
          <h5>Инфографик од истражувањето
            <span class="" *ngFor="let res of graph.researches">
            <span *ngIf="res.ischecked==true">
              <a href="/category/{{category}}/research-list/{{researchId}}" target="blank">{{res.name}}</a>
              </span>
            </span>
          </h5>
        </div>
        <div class="col-md-12 graph-desc" [innerHtml]="graph?.description"></div>
        <div class="col-md-12 graph-img" style="padding: 50px 0px;">
        <img [src]="graph.image_path" style="max-width: 100%" class="graph-img"></div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6"><button class="more_links grey-btn btn"> « Назад кон графиците од истата тема</button>
            </div>
            <div class="col-md-6"><button class="more_links grey-btn btn">Назад кон графиците од истото истражување » </button></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <app-frontend-sidebar [category]="category" [selectType]="selectType"></app-frontend-sidebar>
    </div>
  </div>
</div>
<app-frontend-footer></app-frontend-footer>
