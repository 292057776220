import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {ResearcherService} from '../../shared/services/researcher.service';
import {Researcher} from '../../shared/models/researcher';


@Component({
	selector: 'app-dashboard-researcher-update',
	templateUrl: './dashboard-researcher-update.component.html',
	styleUrls: ['./dashboard-researcher-update.component.css']
})
export class DashboardResearcherUpdateComponent implements OnInit {
	id:any;
	researcherData:any;
	submitted=false;
	researcher:Researcher={
		name:'',
		link:'',
		ischecked:false
	};
	constructor(private researcherService:ResearcherService, private routeId:ActivatedRoute, private router:Router) { }

	ngOnInit(): void {
		this.id = this.routeId.snapshot.paramMap.get('id');
		this.researcherData=this.researcherService.getResearcherData(this.id).valueChanges().subscribe(data=>{
			this.researcher={name:data?.name!,link:data?.link!, ischecked:data?.ischecked!};
			this.researcherData.unsubscribe();
		});
	}

	deleteResearcher(){
		this.researcherService.deleteResearcher(this.id);
		this.router.navigate(['/researcher-list']);
	}


onSubmit(researcherData:any){
	if(researcherData.valid){
		this.submitted=true;
		this.researcherService.updateResearcher(this.id, researcherData.value);
		this.router.navigate(['/researcher-list']);

	}

}

}
