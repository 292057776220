import { Component, OnInit } from '@angular/core';
import {CategoryService} from '../../shared/services/category.service';
import { map } from 'rxjs/operators';
import {Router, ActivatedRoute} from "@angular/router";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-dashboard-category-list',
  templateUrl: './dashboard-category-list.component.html',
  styleUrls: ['./dashboard-category-list.component.css']
})
export class DashboardCategoryListComponent implements OnInit {
    editIcon=faEdit;
	trashIcon=faTrash;
	categories:any;
	constructor(private categoryService:CategoryService, private router:Router) { }

	ngOnInit(): void {
		this.getAllCategories();
	}
	getAllCategories(){
		let listener = this.categoryService.getAllCategories().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))).subscribe(data=>{
			this.categories=data;
			//listener.unsubscribe();
		});
		}

		deleteCategory(id:any){
			this.categoryService.deleteCategory(id);
			let currentUrl = this.router.url;
			this.router.navigateByUrl('/category-list', {skipLocationChange: true}).then(() => {
				this.router.navigate([currentUrl]);
			});
			//this.router.navigate(['category-list']);
		}

}
