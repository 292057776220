<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
        <span class="nav-inside"><a href="/dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
        <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>
<div class="container">
  <div class="dashboard_title">Додади ново истражување</div>
  <form [formGroup]="researchForm" (ngSubmit)="onSubmit(researchForm)">
    <div class="form-group">
      <label for="researchTitle">Наслов</label>
      <input type="text" class="form-control" id="researchTitle" formControlName="title" aria-describedby="emailHelp" placeholder="Внесете наслов" required [ngClass]="{ 'is-invalid': submitted && researchForm.controls.title.errors }" />
        <div *ngIf="submitted && researchForm.controls.title.errors" class="invalid-feedback">
          <div *ngIf="researchForm.controls.title.errors.required"><span>{{'Ова поле е задолжително'}}</span>
          </div>
    </div>
    </div>
    <div class="form-group">
      <label for="abstract">Абстракт</label>
      <angular-editor id="editorAbstract" [placeholder]="'Внесете абстракт'" formControlName="abstract" [ngClass]="{ 'is-invalid':  submitted && researchForm.controls.abstract.invalid }" required [config]="config"></angular-editor>
    </div>
    <div class="form-group">
      <label for="methodology">Методологија</label>
      <angular-editor [placeholder]="'Внесете методологија'"  formControlName="methodology" [ngClass]="{ 'is-invalid':  submitted && researchForm.controls.methodology.invalid }" required [config]="config" id="editorMethodolgy"></angular-editor>
    </div>
     <div class="form-group row">
      <div class="col-md-3"><label for="researchCategory">Категории</label></div>
      <div class="col-md-8">
        <div class="category_box">
          <div *ngFor="let category of categories">
            <input type="checkbox" name="categories" [value]="category.name" (change)="isCategorySelected($event,category.name)" [ngClass]="{ 'is-invalid': submitted && researchForm.controls.categories.errors }">
            {{category.name}}
          </div>
        </div>
        <div *ngIf="submitted && !checkedCat" class=" mandatory_line">
          <div *ngIf="submitted && !checkedCat">
            <span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-1"><button class="chart_add" placement="top" ngbTooltip="Додади категорија" (click)="addCategory()">+</button></div>
    </div>

    <div class="form-group row">
      <div class="col-md-3"><label for="researchResearchers">Истражувачи</label></div>
      <div class="col-md-8">
        <div class="category_box">
          <div *ngFor="let researcher of researchers">
            <input type="checkbox" name="researchers" [value]="researcher.name" (change)="isResearcherSelected($event,researcher.name)" [ngClass]="{ 'is-invalid': submitted && researchForm.controls.researchers.errors }">
            {{researcher.name}}
          </div>
        </div>
        <div *ngIf="submitted && !checkedRes" class=" mandatory_line">
          <div *ngIf="submitted && !checkedRes">
            <span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-1"><button class="chart_add" placement="top" ngbTooltip="Додади категорија" (click)="addResearcher()">+</button></div>
    </div>
    
    <div class="form-group row">
      <div class="col-md-3"><label for="myFile">Слика за истражувањето </label></div>
      <div class="col-md-9"><input type="file" name="myFile" (change)="fileEventImage($event)" />
 <p *ngIf="uploadImageSuccessful==1" class="respo succ">Upload successfull</p> <br />
      <p *ngIf="uploadImageSuccessful==0" class="respo fail">Upload not completed, try again</p> <br />
      </div>
    </div>
    <div class="form-group">
      <label for="researchTitle">Година на издавање</label>
      <input type="text" class="form-control" id="researchYearPublished" formControlName="year_published"  aria-describedby="emailHelp" placeholder="Внесете година" [ngClass]="{ 'is-invalid': submitted && researchForm.controls.year_published.invalid }" required>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="myPdf">Пдф за истражувањето</label></div>
      <div class="col-md-9"><input type="file" name="myPdf" (change)="fileEventPdf($event)" />
 <p *ngIf="uploadPdfSuccessful==1" class="respo succ">Upload successfull</p> <br />
      <p *ngIf="uploadPdfSuccessful==0" class="respo fail">Upload not completed, try again</p> <br />
      </div>
    </div>
    <button type="submit" class="btn btn-primary">Додадете истражување</button>
  </form>
</div>
