import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import {Page} from '../models/page';

@Injectable({
  providedIn: 'root'
})
export class PageService {
private dBpages='/pages';
	pagesRef:AngularFirestoreCollection<Page>;
	constructor(private db:AngularFirestore) {
		this.pagesRef=db.collection(this.dBpages,ref => ref.limit(10));
	}

	createPage(page:Page):any{
		return this.pagesRef.add({...page}).then(() => {
			console.log("Document successfully written!");
		})
		.catch((error) => {
			console.error("Error writing document: ", error);
		});
	}

	// getAllPages(){
	// 	return this.pagesRef;
	// }
	

	getPageData(id:any){
		return this.pagesRef.doc(id).valueChanges();
	}
	updatePage(id:any, data:any){
		return this.pagesRef.doc(id).update(data);
	}
	// deletePage(id:any){
	// 	return this.pagesRef.doc(id).delete();
	// }
}
