<div class="container-fluid admin_back">
<div class="center_box">
  <div class="box">
    <div class="form-group">
    <label for="username">Enter email adress</label>
        <input type="text" class="form-control" placeholder="Username" #userName required>
      </div>
      <div class="form-group">
      <label for="Password">Enter your pass</label>

        <input type="password" class="form-control" placeholder="Password" #userPassword required>
      </div>
      <!-- Calling SignIn Api from AuthService -->
      <div class="form-group">
        <input type="button" class="btn btn-primary" value="Log in" (click)="authService.SignIn(userName.value, userPassword.value)">
      </div>
</div>
</div>

