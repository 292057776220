import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,FormArray, FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {Router, ActivatedRoute} from "@angular/router";

import { ResearchService } from '../../shared/services/research.service';
import {GraphService} from '../../shared/services/graph.service';
import { CategoryService } from '../../shared/services/category.service';

import * as Highcharts from 'highcharts';

@Component({
	selector: 'app-dashboard-graph-add',
	templateUrl: './dashboard-graph-add.component.html',
	styleUrls: ['./dashboard-graph-add.component.css']
})
export class DashboardGraphAddComponent implements OnInit {
	
	graphForm!:FormGroup;
	chart_seriesForm!:FormGroup;
	chart_percent_seriesForm!:FormGroup;
	chart_comb_series!:FormGroup;
	removebutton=false;
	researches:any;
	categories:any;
	ishidden:boolean=false;
	clicked_researches:any[]=[];
	chart_type:any;
	showFrequency:boolean=true;
	showPercent:any;
	submitted=false;
	checkedCat=false;
	checkedRes=false;
	showInfographic=true;
	serie_type:any='column';
	type:any;
	buttonNewPie=true;
	image_path:any;
	secondClick=true;
	new_image:any;
	uploadImageSuccessful:any;
	show_stack=false;
	id:any;
	/* file upload */
	/* Variabe to store file data */
	
	filedata:any;
	constructor(private fb:FormBuilder, private researchService:ResearchService, private categoryService:CategoryService, private http:HttpClient, private graphService:GraphService, private router:Router, private rout:ActivatedRoute) {
	}

	async ngOnInit() {
	this.id = this.rout.snapshot.paramMap.get('id');

		
		this.graphForm = this.fb.group({
			graphId:'',
			title:['',Validators.required],
			description:['',Validators.required],
			researches:this.fb.array([]),
			categories:this.fb.array([]),
			source:['',Validators.required],
			source_link:['',Validators.required],
			chart_title:'',
			chart_subtitle:'',
			chart_xaxis:'',
			chart_yaxis:'',
			// chart_cat_name:'',
			chart_series:this.fb.array([this.fb.group({
				name:'',
				data:'',
				color:'',
				stack:''
			})]),
			// new
			chart_series_combined:this.fb.array([this.fb.group({
				column_bar_line:this.fb.array([ this.fb.group({
					name:'',
					type:'',
					data:'',
					color:'',
				})]),
				pie:this.fb.array([this.fb.group({
					name:'',
					type:'pie',
					data:this.fb.array([this.fb.group({
						y:'',
						color:'',
						name:''
					})]),


				})])
			})]),

			//end
			chart_type:'chart_type_frequency',
			graphic_type_view:'график',
			chart_primary_type:'column',
			chart_stack:'undefined',
			image_path:'/assets/img/default_chart.png',
			date:new Date()

		});
		this.getAllResearches();
		this.getAllCategories();
		
	}

	test(a:any){
		console.log(a.target.value);
		if(a.target.value=='normal'){
			this.show_stack=true
		}else{
			this.show_stack=false
		}

		}


	chart_series_combined():FormArray{
		return this.graphForm.get('chart_series_combined') as FormArray;
	}

	// column bar line

	getColumnBarLine(serieIndex:any):FormArray{
		return this.chart_series_combined().at(serieIndex).get('column_bar_line') as FormArray;
	}

	newColumnLinkeBarData(){
		return this.fb.group({
			name:'',
			data:'',
			type:'',
			color:'',
			stack:''
		})
	}

	addNewColumnBarLine(serieIndex:number){
		this.getColumnBarLine(serieIndex).push(this.newColumnLinkeBarData());
		this.secondClick=false;
	}

	removeColumnBarLine(serieindex:number,dataindex:number){
		this.getColumnBarLine(serieindex).removeAt(dataindex);
		this.secondClick=true;
	}

	//end column_bar_line


	// pie
	getPie(serieIndex:any):FormArray{
		return this.chart_series_combined().at(serieIndex).get('pie') as FormArray;
	}

	getPieDataInData(serieindex:number,dataIndex:number):FormArray{
		return this.getPie(serieindex).at(dataIndex).get('data') as FormArray;
	}

	addNewPieDataInData(serieindex:any, dataindex:number){
		this.getPieDataInData(serieindex,dataindex).push(this.newDataInData());
	}

	removePieDatainData(serieindex:number,piedataindex:number,dataindex:number){
		this.getPieDataInData(serieindex,piedataindex).removeAt(dataindex);
	}


	newPieData(){
		return this.fb.group({
			name:'',
			type:'pie',
			data:this.fb.array([this.newDataInData()])
		});
	}



	newDataInData(){
		return this.fb.group({
			y:'',
			color:'',
			name:''
		})
	}


	addPieData(serieindex:number,dataindex:number){
		this.buttonNewPie=false;
		this.getPie(dataindex).push(this.newPieData());


	}

	//end pie



	chart_series(){
		return this.graphForm.get('chart_series') as FormArray;
	}


	addSeries(){
		this.chart_seriesForm=this.fb.group({
			name:'',
			data:'',
			color:'',
			stack:''
		});
		this.chart_series().push(this.chart_seriesForm);
	}

	removeSeries(index:number){
		this.chart_series().removeAt(index);
	}

	changeChart(chart_type:any){
		console.log(chart_type.target.value);
		this.serie_type=chart_type.target.value;
		//console.log(this.chart_percent_series().value.length);

	}
	changeComboChart(chart_type:any){
		this.type=chart_type.target.value;
	}


	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "100px",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "yes",
		defaultParagraphSeparator: "div",
		defaultFontName: "PT Sans",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: false,
		customClasses: [
		{
			name: "centerTextImage",
			class: "centerTextImage",
		},
		{	name:"article_img",
		class:"article_img"
	}],
	// uploadUrl: '',
	//    uploadWithCredentials: false,	

};

isResearchSelected(event:any, name:string){
	this.checkedRes=false;
	var arrayRes=this.graphForm.get('researches') as FormArray;

	if(event.target.checked){

		arrayRes.controls.forEach(function (element,index) {
			if(element.value.name==name){
				arrayRes.at(index).patchValue({name:element.value.name,ischecked:true});
			}
		})
	}else{

		arrayRes.controls.forEach(function (element,index) {
			if(element.value.title==name){
				arrayRes.at(index).patchValue({name:element.value.title,ischecked:false});
			}
		})
	}

	//for validators
	for(let checked of arrayRes.controls){
		if(checked.value.ischecked==true){
			this.checkedRes=true;
		}
	}
}



isCategorySelected(event:any, nameCat:string){
	this.checkedCat=false;
	var arrayCat=this.graphForm.get('categories') as FormArray;

	if(event.target.checked){

		arrayCat.controls.forEach(function (element,index) {
			if(element.value.name==nameCat){
				arrayCat.at(index).patchValue({name:element.value.name,ischecked:true});
			}
		})
	}else{

		arrayCat.controls.forEach(function (element,index) {
			if(element.value.name==nameCat){
				arrayCat.at(index).patchValue({name:element.value.name,ischecked:false});
			}
		})
	}

	//for validators
	for(let checked of arrayCat.controls){
		if(checked.value.ischecked==true){
			this.checkedCat=true;
		}
	}
}



getAllResearches(){
	let listener = this.researchService.getAllResearchesForGraph().snapshotChanges().pipe(
		map(actions => actions.map(a => {
			const data = a.payload.doc.data();
			const id = a.payload.doc.id;
			return { ...data };
		}))).subscribe(data=>{
		for(let res of data){

			(this.graphForm.get('researches') as FormArray).push(new FormGroup({
				'name' : new FormControl(res.title),
				ischecked: new FormControl(false)
			}))
		}
		this.researches=data;
		listener.unsubscribe();
	});
	}

	getAllCategories(){
		let listener = this.categoryService.getAllCategories().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { ...data };
			}))).subscribe(data=>{
			for(let cat of data){

				(this.graphForm.get('categories') as FormArray).push(new FormGroup({
					'name' : new FormControl(cat.name),
					ischecked: new FormControl(false)
				}))
			}

			this.categories=data;
			listener.unsubscribe();
		});

		}
		addCategory(){
			window.open('/category-add', 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=750');
			let currentUrl = this.router.url;
			this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
				this.router.navigate([currentUrl]);
			});

		}

		addResearch(){
			window.open('/research-add', 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=750');
			let currentUrl = this.router.url;
			this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
				this.router.navigate([currentUrl]);
			});
		}


		/* File onchange event */
	fileEvent(e:any){
		var file = e.target.files[0];
		var filename=file.name;
		var lastThree=filename.substr(filename.length - 4);
		// var getTitle=this.graphForm.get('title')!.value;
		// getTitle=getTitle.split(' ').join('');
		var getTitle=this.id;
		var graphName="_"+getTitle+lastThree;
		let formData = new FormData();
		formData.append('file', file, graphName);
		this.postImage(formData);




	}
	postImage(file:any){
		this.http.post('/assets/img/uploads/graphs/saveImage.php',file)
		.subscribe(res => {
			//console.log(res);
			this.new_image=res;
			this.uploadImageSuccessful=this.new_image.status;

			console.log(res);
			
			//send success response
		}, (err) => {
			console.log(err)
			//send error response
		});
	}



		showType(name:any) {
			if(name.target.value==="chart_type_frequency"){
				this.showFrequency = true;
				this.showPercent=false;
			}else{
				this.showFrequency = false;
				this.showPercent=true;
			}
		}

		graphicInfographic(name:any){
			if(name.target.value==="инфографик"){
				this.showInfographic=false;

			}else{
				this.showInfographic=true;
			}
		}



		async onSubmit(formValue:any){
			console.log(formValue);


			this.submitted=true;
			if(this.graphForm.invalid){
				return;
			}
			if(this.new_image){
			var imgPath=this.new_image.image_path;
			var lastThree=imgPath.substr(imgPath.length - 4)
			// var getTitle=this.graphForm.get('title')!.value;
			// getTitle=getTitle.split(' ').join('');
			var getTitle=this.id;
			var graphName="_"+getTitle+lastThree;

			this.graphForm.get('image_path')!.patchValue('/assets/img/uploads/graphs/'+graphName);
		}
			
			var chart_series;
			chart_series=this.graphForm.get('chart_series')!.value;
			for(let serie of chart_series){
				if(!Array.isArray(serie.data)){
					serie.data=serie.data.split(',').map(function(item:any) {
						return parseInt(item, 10);
					});
				}else{

				}
			}

			this.graphService.createGraph(formValue.value);
			this.router.navigate(['graph-list']);

		}

	}
