import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { map } from 'rxjs/operators';
import {ResearchService} from '../../shared/services/research.service';
import {Research} from '../../shared/models/research';
@Component({
	selector: 'app-frontend-research-all',
	templateUrl: './frontend-research-all.component.html',
	styleUrls: ['./frontend-research-all.component.css']
})
export class FrontendResearchAllComponent implements OnInit {
	constructor(private rout:ActivatedRoute, private researchService:ResearchService) { }
	researchList:any;
	firstResearch:any;
	mainFirstResearch:any;
	lastResearch:any;
	disableNextButton:any;
	disablePrevButton=true;
	firstCategory:any;
	ngOnInit(){
		this.getAllR()
	}

	getAllR(){

		var listener=this.researchService.getAllResearches().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))).subscribe(allresearches=>{
			this.researchList=allresearches;
			this.firstResearch=this.researchList[0];
			this.mainFirstResearch=this.researchList[0];
			this.lastResearch=this.researchList[this.researchList.length-1];
			console.log(this.lastResearch);

			//first category true for the url

			for(var cat of allresearches){
				console.log(cat.categories);
				for(var c of cat.categories){
					if(c.ischecked==true){
					this.firstCategory=c.name;
				}
			}
		}


			if(this.researchList.length==10){
				//check for next item
			var myNextlistener=this.researchService.nextResearchDataOne(this.lastResearch).snapshotChanges().subscribe(a=>{
				if(a.length!=0){
					this.disableNextButton=false;
				}else{
					this.disableNextButton=true;
				}
				myNextlistener.unsubscribe();
			});
			}else{
				this.disableNextButton=true;
			}

			listener.unsubscribe();
		});
		

	}

	nextResearchPage(){
			
			let listener=this.researchService.nextResearchData(this.lastResearch).snapshotChanges().pipe(
				map(actions => actions.map((a:any) => {
					const data = a.payload.doc.data();
					const id = a.payload.doc.id;
					return { id, ...data };
				}))
				).subscribe(data => {
					this.disableNextButton = true;
					var removeCount = 0;
					if (data.length == 11) {
						removeCount = 1;
						this.disableNextButton = false;
					}
					var newArrayResearcher = [];
					for(let i=0;i<data.length-removeCount;i++){
						newArrayResearcher.push(data[i]);	
					}
					this.researchList = newArrayResearcher;
					this.firstResearch=newArrayResearcher[0];
					this.lastResearch=newArrayResearcher[newArrayResearcher.length-1];

					this.disablePrevButton=false;
					listener.unsubscribe();
				});

			}

			prevResearchPage(){
				let listener=this.researchService.prevResearchData(this.firstResearch).snapshotChanges().pipe(
					map(actions => actions.map((a:any) => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					}))
					).subscribe(data => {
						this.researchList = data;
						this.firstResearch=data[0];
						this.lastResearch=data[data.length-1];
						this.disableNextButton=false;
						if(this.mainFirstResearch.title===this.firstResearch.title){
							this.disablePrevButton=true;
						}
						listener.unsubscribe();
					});
				}

}
