import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,FormArray, FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {Router, ActivatedRoute} from "@angular/router";

import { ResearcherService } from '../../shared/services/researcher.service';
import {GraphService} from '../../shared/services/graph.service';
import { CategoryService } from '../../shared/services/category.service';

@Component({
	selector: 'app-dashboard-graph-update',
	templateUrl: './dashboard-graph-update.component.html',
	styleUrls: ['./dashboard-graph-update.component.css']
})
export class DashboardGraphUpdateComponent implements OnInit {
	id:any;
	graphData:any;
	graphForm!:FormGroup;
	chart_seriesForm!:FormGroup;
	chart_percent_seriesForm!:FormGroup;
	removebutton=false;
	chart_series_show=false;
	chart_combined_series_column=false;
	chart_combined_series_pie=false;
	
	ishidden:boolean=false;
	clicked_researchers:any[]=[];
	chart_type:any;
	submitted=false;
	isinfographic:any;
	image_path:any;
	new_image:any;
	uploadImageSuccessful:any;
	show_stack=false
	/* file upload */
	/* Variabe to store file data */
	
	filedata:any;
	constructor(public router:Router,
		private rout:ActivatedRoute,
		private graphService:GraphService,
		private categoryService:CategoryService,
		private fb: FormBuilder,
		private http: HttpClient) { }

	ngOnInit(): void {

		this.id = this.rout.snapshot.paramMap.get('id');
		this.graphForm = this.fb.group({
			title:'',
			description:'',
			researches:this.fb.array([]),
			categories:this.fb.array([]),
			source:'',
			source_link:'',
			chart_title:'',
			chart_subtitle:'',
			chart_xaxis:'',
			chart_yaxis:'',
			chart_cat_name:'',
			chart_series:this.fb.array([]),
			chart_series_combined:this.fb.array([]),
			chart_type:'',
			chart_primary_type:'',
			chart_stack:'null',
			image_path:'',
			date:new Date()

		});

		
		this.graphData=this.graphService.getGraphData(this.id).valueChanges().subscribe(data=>{
			
			if(data?.chart_type=='chart_type_frequency'){
				this.chart_series_show=true;
			}else{
				//this.chart_combined_series=true;
			}

			if(data?.graphic_type_view=='график'){
				this.isinfographic=true;
			}else{
				this.isinfographic=false;
			}


			this.graphForm.patchValue({
				title:data?.title,
				description:data?.description,
				source:data?.source,
				source_link:data?.source_link,
				chart_title:data?.chart_title,
				chart_subtitle:data?.chart_subtitle,
				chart_xaxis:data?.chart_xaxis,
				chart_yaxis:data?.chart_yaxis,
				chart_cat_name:'',
				chart_type:data?.chart_type,
				graphic_type_view:data?.graphic_type_view,
				chart_primary_type:data?.chart_primary_type,
				chart_stack:data?.chart_stack,
				categories:data?.categories,
				researches:data?.researches,
				image_path:data?.image_path,
				date:new Date()
			});
			this.image_path=this.graphForm.get('image_path')!.value;	
			data?.categories.forEach((value,key)=>{
				(this.graphForm.get('categories') as FormArray).push(this.fb.group({
					name:value.name,
					ischecked:value.ischecked
				}))
			});

			data?.researches.forEach((value,key)=>{
				(this.graphForm.get('researches')as FormArray).push(this.fb.group({
					name:value.name,
					ischecked:value.ischecked
				}))
			});

			if(data?.graphic_type_view!="инфографик"){

				data?.chart_series.forEach((value, key) => {
					if(value.stack){
						this.show_stack=true;
					}else{
						this.show_stack=false;
					}
					(this.graphForm.get('chart_series') as FormArray).push(this.fb.group({
						name:value.name,
						data:value.data.toString(),
						stack:value.stack,
						color:value.color.toString()
					}));

				});


				data?.chart_series_combined.forEach((value, key) => {
					let column_bar_line_data=[];
					let pie_data=[];
					let in_data_pie_data=[];
					
					if(value.pie){

						this.chart_combined_series_column=false;
						this.chart_combined_series_pie=true;

						for(let pie of value.pie){
							in_data_pie_data=[];
							for(let data_pie of pie.data){
								in_data_pie_data.push(this.fb.group({
									name:data_pie.name,
									y:data_pie.y,
									color:data_pie.color
								}))
							}
							let in_data_pieForm=new FormArray(in_data_pie_data);
							pie_data.push(this.fb.group({
								name:pie.name,
								type:pie.type,
								data:in_data_pieForm,
							}))
						}
						let pieForm=new FormArray(pie_data);
						(this.graphForm.get('chart_series_combined') as FormArray).push(this.fb.group({
							pie:pieForm
						}))





					}else{
						this.chart_combined_series_column=true;
						this.chart_combined_series_pie=false;
						for(let col of value.column_bar_line){
							column_bar_line_data.push(this.fb.group({
								name:col.name,
								color:col.color,
								type:col.type,
								data:col.data,
							}))
						}
						let columnForm=new FormArray(column_bar_line_data);
						(this.graphForm.get('chart_series_combined') as FormArray).push(this.fb.group({
							column_bar_line:columnForm
						}))
					}



				});
			}



			this.graphData.unsubscribe();
		});

		


	}

	categories(){
		return this.graphForm.get('categories') as FormArray;
	}
	
	researches(){
		return this.graphForm.get('researches') as FormArray;
	}


	
	chart_series(){
		return this.graphForm.get('chart_series') as FormArray;
	}

	addSeries(){
		this.chart_seriesForm=this.fb.group({
			name:'',
			data:'',
			color:''
		});
		this.chart_series().push(this.chart_seriesForm);
	}

	removeSeries(index:number){
		this.chart_series().removeAt(index);
	}

	// end chart_series


	chart_series_combined():FormArray{
		return this.graphForm.get('chart_series_combined') as FormArray;
	}

	// column_bar_line

	getColumnBarLine(serieIndex:any):FormArray{
		return this.chart_series_combined().at(serieIndex).get('column_bar_line') as FormArray;
	}

	newColumnLinkeBarData(){
		return this.fb.group({
			name:'',
			data:'',
			type:'',
			color:'',
		})
	}

	addNewColumnBarLine(serieIndex:number){
		this.getColumnBarLine(serieIndex).push(this.newColumnLinkeBarData());
	}

	removeColumnBarLine(serieindex:number,dataindex:number){
		this.getColumnBarLine(serieindex).removeAt(dataindex);
	}
	//end column_bar_line

	// pie
	getPie(serieIndex:any):FormArray{
		return this.chart_series_combined().at(serieIndex).get('pie') as FormArray;
	}

	getPieDataInData(serieindex:number,dataIndex:number):FormArray{
		return this.getPie(serieindex).at(dataIndex).get('data') as FormArray;
	}

	addNewPieDataInData(serieindex:any, dataindex:number){
		this.getPieDataInData(serieindex,dataindex).push(this.newDataInData());
	}

	removePieDatainData(serieindex:number,piedataindex:number,dataindex:number){
		this.getPieDataInData(serieindex,piedataindex).removeAt(dataindex);
	}


	newPieData(){
		return this.fb.group({
			name:'',
			type:'pie',
			data:this.fb.array([this.newDataInData()])
		});
	}

	newDataInData(){
		return this.fb.group({
			y:'',
			color:'',
			name:''
		})
	}

	addPieData(serieindex:number,dataindex:number){
		//this.buttonNewPie=false;
		this.getPie(dataindex).push(this.newPieData());


	}
	//end pie


	


	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "100px",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "div",
		defaultFontName: "PT Sans",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: true,
		customClasses: [
		{
			name: "centerTextImage",
			class: "centerTextImage",
		},
		{	name:"article_img",
		class:"article_img"
	}],
	// uploadUrl: '',
	//    uploadWithCredentials: false,	

};
isResearchSelected(event:any, name:string){
	var arrayRes=this.researches();
	if(event.target.checked){

		arrayRes.controls.forEach(function (element:any,index:any) {
			if(element.value.name==name){
				arrayRes.at(index).patchValue({name:element.value.name,ischecked:true});
			}
		})
	}else{

		arrayRes.controls.forEach(function (element:any,index:any) {
			if(element.value.name==name){
				arrayRes.at(index).patchValue({name:element.value.name,ischecked:false});
			}
		})
	}
}

isCategorySelected(event:any, nameCat:string){

	var arrayCat=this.categories();

	if(event.target.checked){

		arrayCat.controls.forEach(function (element,index) {
			if(element.value.name==nameCat){
				arrayCat.at(index).patchValue({name:element.value.name,ischecked:true});
			}
		})
	}else{

		arrayCat.controls.forEach(function (element,index) {
			if(element.value.name==nameCat){
				arrayCat.at(index).patchValue({name:element.value.name,ischecked:false});
			}
		})
	}

}
addCategory(){
	window.open('/category-add', 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=750');
	// let currentUrl = this.router.url;
	// this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
		// 		this.router.navigate([currentUrl]);
		// 	});

	}



	/* File onchange event */
	fileEvent(e:any){
		var file = e.target.files[0];
		var filename=file.name;
		var lastThree=filename.substr(filename.length - 4);
		// var getTitle=this.graphForm.get('title')!.value;
		// getTitle=getTitle.split(' ').join('');
		var getTitle=this.id;
		var graphName="_"+getTitle+lastThree;
		let formData = new FormData();
		formData.append('file', file, graphName);
		this.postImage(formData);




	}
	postImage(file:any){
		this.http.post('/assets/img/uploads/graphs/saveImage.php',file)
		.subscribe(res => {
			//console.log(res);
			this.new_image=res;
			this.uploadImageSuccessful=this.new_image.status;

			console.log(res);
			
			//send success response
		}, (err) => {
			console.log(err)
			//send error response
		});
	}

	deleteGraph(){
		this.graphService.deleteGraph(this.id);
		this.router.navigate(['/graph-list']);
	}

	onSubmit(formValue:any){
		this.submitted=true;
		var chart_series;
		var chart_series_combined;
		console.log(this.image_path);
		if(this.new_image){
			var imgPath=this.new_image.image_path;
			var lastThree=imgPath.substr(imgPath.length - 4)
			// var getTitle=this.graphForm.get('title')!.value;
			// getTitle=getTitle.split(' ').join('');
			var getTitle=this.id;
			var graphName="_"+getTitle+lastThree;

			this.graphForm.get('image_path')!.patchValue('/assets/img/uploads/graphs/'+graphName);
		}


		
		
		chart_series=this.graphForm.get('chart_series')!.value;
		if(chart_series.length!=[]){
			for(let serie of chart_series){
				if(!Array.isArray(serie.data)){
					//serie.color=serie.color.split(',');
					serie.data=serie.data.split(',').map(function(item:any) {
						return parseInt(item, 10);
					});
				}else{

				}
			}
		}else{



			chart_series_combined=this.graphForm.get('chart_series_combined')!.value;

			for(let combined of chart_series_combined){
				if(combined.column_bar_line){
					for(let column_bar_line of combined.column_bar_line){
						if(!Array.isArray(column_bar_line.data)){
							//serie.color=serie.color.split(',');
							column_bar_line.data=column_bar_line.data.split(',').map(function(item:any) {
								return parseInt(item, 10);
							});
						}

					}

				}else{



				}
			}
		}


		this.graphService.updateGraph(this.id,formValue.value);
		//		console.log(formValue.value);
		this.router.navigate(['/graph-list']);
		
	}

}
