<app-dashboard-header></app-dashboard-header>
<div class="container-fluid" style="padding:0">
	<ul class="list-group">
		<li class="list-group-item"><a href="/category-list">Категории</a></li>
		<li class="list-group-item"><a href="/researcher-list">Истражувачи</a></li>
		<li class="list-group-item"><a href="/research-list">Истражувања</a></li>
		<li class="list-group-item"><a href="/graph-list">Графици - Инфографици</a></li>
	</ul>
	<div style="padding: 30px 0px"></div>
	<ul class="list-group">
		<li class="list-group-item"><a href="/dashboard/about-page">За страницата</a></li>
		<li class="list-group-item"><a href="/dashboard/about-us">За нас</a></li>
		<li class="list-group-item"><a href="/dashboard/contact">Контакт</a></li>
	</ul>
</div>