<app-frontend-header></app-frontend-header>
<div class="before-page">
<div class="container page">
	<div class="row">
		<div class="col-12">
			<h3 class="main-title">{{pageData?.title}}</h3>
			 <span [innerHTML]="pageData?.description"></span>
		</div>
	</div>
</div>
<app-frontend-footer></app-frontend-footer>
</div>

