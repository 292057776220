import { Component, OnInit } from '@angular/core';
import {CategoryService} from '../../shared/services/category.service';
import { Router } from "@angular/router";
import {Category} from '../../shared/models/category';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-dashboard-category-add',
	templateUrl: './dashboard-category-add.component.html',
	styleUrls: ['./dashboard-category-add.component.css']
})
export class DashboardCategoryAddComponent implements OnInit {
	submitted=false;
	category={} as Category;

	constructor(public categoryService:CategoryService, private router:Router) { }
	ngOnInit(): void {}

	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "200px",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "div",
		defaultFontName: "PT Sans",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: false,
		customClasses: [
		
		{
			name: "centerTextImage",
			class: "centerTextImage",
		},
		{	name:"article_img",
			class:"article_img"
		}],
	 // uploadUrl: '',
	 //    uploadWithCredentials: false,	

	};

	onSubmit(categoryData:any){
		if(categoryData.valid){
			this.submitted=true;
			this.categoryService.createCategory(categoryData.value);
			this.router.navigate(['/category-list']);
		}
		
	}

}
