import { Component, OnInit } from '@angular/core';
import {ResearchService} from '../../shared/services/research.service';
import { map } from 'rxjs/operators';
import {Router, ActivatedRoute} from "@angular/router";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
@Component({
	selector: 'app-dashboard-research-list',
	templateUrl: './dashboard-research-list.component.html',
	styleUrls: ['./dashboard-research-list.component.css']
})
export class DashboardResearchListComponent implements OnInit {
	editIcon=faEdit;
	trashIcon=faTrash;
	researches:any;
	disableNextButton:any;
	disablePrevButton=true;
	firstResearch:any;
	mainFirstResearch:any;
	lastResearch:any;
	searchBox:any;
	constructor(private researchService:ResearchService, private router:Router) { }

	ngOnInit(): void {
		this.getAllResearches();
	}
	getAllResearches(){

		let listener = this.researchService.getAllResearches().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))).subscribe(data=>{
			this.researches=data;
			
			this.firstResearch=data[0];
			this.mainFirstResearch=data[0];
			this.lastResearch=data[data.length-1];

			//check for next item
			if(this.researches.length==10){
				var myNextlistener=this.researchService.nextResearchDataOne(this.lastResearch).snapshotChanges().subscribe((a:any)=>{
					if(a.length!=0){
						this.disableNextButton=false;
					}else{
						this.disableNextButton=true;
					}
					myNextlistener.unsubscribe();
				});
			}else{
				this.disableNextButton=true;
			}
			listener.unsubscribe();
		});
		}

		nextResearchPage(){
			
			let listener=this.researchService.nextResearchData(this.lastResearch).snapshotChanges().pipe(
				map(actions => actions.map(a => {
					const data = a.payload.doc.data();
					const id = a.payload.doc.id;
					return { id, ...data };
				}))
				).subscribe(data => {
					this.disableNextButton = true;
					var removeCount = 0;
					if (data.length == 11) {
						removeCount = 1;
						this.disableNextButton = false;
					}
					var newArrayResearch = [];
					for(let i=0;i<data.length-removeCount;i++){
						newArrayResearch.push(data[i]);	
					}
					this.researches = newArrayResearch;
					this.firstResearch=newArrayResearch[0];
					this.lastResearch=newArrayResearch[newArrayResearch.length-1];

					this.disablePrevButton=false;
					listener.unsubscribe();
				});

			}

			prevResearchPage(){
				let listener=this.researchService.prevResearchData(this.firstResearch).snapshotChanges().pipe(
					map(actions => actions.map(a => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					}))
					).subscribe(data => {
						this.researches = data;
						this.firstResearch=data[0];
						this.lastResearch=data[data.length-1];
						this.disableNextButton=false;
						if(this.mainFirstResearch.title===this.firstResearch.title){
							this.disablePrevButton=true;
						}
						listener.unsubscribe();
					});
				}

				search(name:any){
					let listener = this.researchService.searchResearch(name).snapshotChanges().pipe(
						map(actions => actions.map(a => {
							const data = a.payload.doc.data();
							const id = a.payload.doc.id;
							return { id, ...data };
						}))).subscribe(data=>{
						this.researches=data;
						listener.unsubscribe();
					});
						if(name==''){
							this.getAllResearches();
						}
					}




					deleteResearch(id:any){
						this.researchService.deleteResearch(id);
						let currentUrl = this.router.url;
						this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
							this.router.navigate([currentUrl]);
						});
					}

				}
