import { Injectable, NgZone } from '@angular/core';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	userData: any; // Save logged in user data
	constructor(
		private afAuth: AngularFireAuth,
		public afs: AngularFirestore,   // Inject Firestore service
		private router: Router,
		private ngZone: NgZone, private http:HttpClient) {

		this.afAuth.authState.subscribe((user) => {
			if (user) {
				this.userData = user;
				localStorage.setItem('user', JSON.stringify(this.userData));
				JSON.parse(localStorage.getItem('user')!);
			} else {
				localStorage.setItem('user', 'null');
				JSON.parse(localStorage.getItem('user')!);
			}
		});

	}

	SetUserData(user:any) {
		const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
		const userData: User = {
			uid: user.uid,
			email: user.email,
		}
		return userRef.set(userData, {
			merge: true
		})
	}

	SignIn(email: string, password: string) {
		return this.afAuth
		.signInWithEmailAndPassword(email, password)
		.then((result) => {
			this.ngZone.run(() => {
				this.router.navigate(['dashboard']);
			});
			this.SetUserData(result.user);
		})
		.catch((error) => {
			window.alert(error.message);
		});
	}

	// Returns true when user is looged in and email is verified
	get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null ? true : false;
  }   


	SignOut() {
		return this.afAuth.signOut().then(() => {
			localStorage.removeItem('user');
			this.router.navigate(['sign-in']);
		});
	}


	sendSimpleCheckMail(data:any){
		var date=new Date();
		const obj = {title: data.value.messageTitle,date:date, description:data.value.messageDes, email:data.value.messageEmail};
		const myJSON = JSON.stringify(obj);
		this.http.post("/assets/send_ask_row_data.php",myJSON,{responseType: 'text'}).subscribe(a=>{
			console.log(a);
		});
	}
}
