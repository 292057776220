import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import {Category} from '../models/category';

@Injectable({
	providedIn: 'root'
})
export class CategoryService {
	private dBcategories='/categories';
	categoriesRef:AngularFirestoreCollection<Category>;
	constructor(private db:AngularFirestore) {
		this.categoriesRef=db.collection(this.dBcategories,ref => ref.limit(10));
	}

	createCategory(category:Category):any{
		return this.categoriesRef.add({...category}).then(() => {
			console.log("Document successfully written!");
		})
		.catch((error) => {
			console.error("Error writing document: ", error);
		});
	}

	getAllCategories(){
		return this.categoriesRef;
	}
	async getAsyncCategories(){
		return await new Promise((resolve:any, reject:any) => {
			var getCat:any;
			let getCatListener=this.categoriesRef.valueChanges().subscribe(data => {
				getCat=data;
				resolve(getCat);
				getCatListener.unsubscribe();
			});
		});
	}

	getCategoryData(id:any){
		return this.categoriesRef.doc(id);
	}
	getCategoryDataByName(name:any){
		return this.categoriesRef=this.db.collection(this.dBcategories,ref => ref.where('name','==',name));
	}
	updateCategory(id:any, data:any){
		return this.categoriesRef.doc(id).update(data);
	}
	deleteCategory(id:any){
		return this.categoriesRef.doc(id).delete();
	}

	
}
