<app-frontend-header></app-frontend-header>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h3 class="main-title">{{category}}</h3>
      <h4 class="main-title">Истражувања</h4>
    </div>
  </div>
  <div class="breadcrumbs">
    <a href="/"><span>Почетна</span></a>
    <a href="/category/{{category}}"><span>{{category}}</span></a>
    <a href="/category/{{category}}/research-list/"><span>Истражувања</span></a>
  </div>
  <div class="row">
    <div class="col-md-9">
      <div class="row" *ngFor="let res of researchData">
        <div class="col-md-12">
          <h4 class="title">{{res.title}}</h4>
          <div class="researcher_block">
            <div class="researcher-box">
              <span class="researcher-icon icon-size"></span>
              <span style="display: inline-block;">
                <h4>Истражувач</h4>
              </span>
            </div>
            <div class="text">
              <span class="a" *ngFor="let re of res.researchers">
              
                <span class="link" *ngIf="re.ischecked==true">
                  <a [href]="re.link" target="_blank">{{re.name}}, </a>
                </span>
              </span>
            </div>
          </div>
          <div class="abstract_block">
            <div class="abstract-box">
              <span class="abstract-icon icon-size"></span>
              <span style="display: inline-block;">
                <h4>Апстракт</h4>
              </span>
            </div>
            <div class="text" [innerHtml]="res?.abstract"></div>
          </div>
          <div class="metodology_block">
            <div class="metodology-box">
              <span class="metodology-icon icon-size"></span>
              <span style="display: inline-block;">
                <h4>Методологија</h4>
              </span>
            </div>
            <div class="text" [innerHtml]="res?.methodology"></div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="res?.pdf_path">
          <div class="download-research">
            <a href="{{res?.pdf_path}}" target="_blank"><button class="purple-btn btn-large btn btn-primar" type="button">Преземи го истражувањето</button></a>
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-3">
      <app-frontend-sidebar [category]="category" [selectType]="selectType"></app-frontend-sidebar>
    </div>
     <div class="row" style="width: 100%">
        <div class="col-md-12">
          <div class="data-viz_block">
            <div class="data-viz-box">
              <span class="data-viz-icon icon-size"></span>
              <span style="display: inline-block;">
                <h4>Визуелизирани податоци за истражувањето</h4>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="align-content: center;justify-content: center;width: 100%">
        <div class="" *ngFor="let graph of graphData" placement="top" ngbTooltip="{{graph.data.title}}" style="text-align: center;margin:0px 3.8% 0%">
        <div *ngIf="graph.data.graphic_type_view=='график'">
          <a href="/category/{{category}}/graph-list/{{graph.id}}">
            <div class="boxImage" style="background-image:url('{{graph.data?.image_path}}')"></div>
          </a>
        </div>
        <div *ngIf="graph.data.graphic_type_view=='инфографик'">
          <a href="/category/{{category}}/infographic-list/{{graph.id}}">
            <div class="boxImage" style="background-image:url('{{graph.data?.image_path}}')"></div>
          </a>
        </div>
          
        </div>
      </div>
  </div>
</div>
<app-frontend-footer></app-frontend-footer>
