import { Component, OnInit } from '@angular/core';
import {GraphService} from '../../shared/services/graph.service';
import {ResearchService} from '../../shared/services/research.service';

import { map } from 'rxjs/operators';
import {Router, ActivatedRoute} from "@angular/router";

@Component({
	selector: 'app-frontend-search',
	templateUrl: './frontend-search.component.html',
	styleUrls: ['./frontend-search.component.css']
})
export class FrontendSearchComponent implements OnInit {
	graphList:any;
	researchList:any;
	infographList:any;
	parm:any;
	emptyGraph=false;
	emptyInfograph=false;
	emptyResearch=false;
	//graph
	firstGraph:any;
	mainFirstGraph:any;
	lastGraph:any;
	disableNextButtonGraph:any;
	disablePrevButtonGraph=true;
	//infograph
	firstInfograph:any;
	mainFirstInfograph:any;
	lastInfograph:any;
	disableNextButtonInfograph:any;
	disablePrevButtonInfograph=true;
	//research
	firstResearch:any;
	mainFirstResearch:any;
	lastResearch:any;
	disableNextButtonResearch:any;
	disablePrevButtonResearch=true;
	

	constructor(private graphService:GraphService, private rout:ActivatedRoute, private researchService:ResearchService, private router:Router) { }

	ngOnInit(): void {
		this.parm = this.rout.snapshot.paramMap.get('searchtext');
		this.getGraphs();
		this.getInfographs();
		this.getResearches();

		this.translateFromUtf8();
	}

	translateFromUtf8(){
		var aman="";
		var b=this.parm;
		var a:any={"а":"a","л":"l","е":"e"};
		for(var i=0;i<b.length;i++){
			if(a[b[i]]===undefined){
				aman+="_";
			}else if(a[b[i]]){
				aman+=a[b[i]];
			}else{
				aman+=b[i];
			}
			//aman += a[b[i]]==='undefined' ? '_' : a[b[i]];
		}
		console.log(aman);


	}

	// graph
	getGraphs(){
		let listener = this.graphService.searchGraph(this.parm).snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))).subscribe(data=>{
			this.graphList=data;
			if(data.length==0){
				this.emptyGraph=true;
			}

			this.firstGraph=this.graphList[0];
			this.mainFirstGraph=this.graphList[0];
			this.lastGraph=this.graphList[this.graphList.length-1];

			//check for next item
			if(this.graphList.length==10){
				var myNextlistener=this.graphService.nextGraphDataOne(this.lastGraph).snapshotChanges().subscribe(a=>{
					if(a.length!=0){
						this.disableNextButtonGraph=false;
					}else{
						this.disableNextButtonGraph=true;
					}
					myNextlistener.unsubscribe();
				});
			}else{
				this.disableNextButtonGraph=true;

			}

			listener.unsubscribe();
		});

		}
		nextGraphPage(){
			
			let listener=this.graphService.nextGraphData(this.lastGraph).snapshotChanges().pipe(
				map(actions => actions.map((a:any) => {
					const data = a.payload.doc.data();
					const id = a.payload.doc.id;
					return { id, ...data };
				}))
				).subscribe(data => {
					this.disableNextButtonGraph = true;
					var removeCount = 0;
					if (data.length == 11) {
						removeCount = 1;
						this.disableNextButtonGraph = false;
					}
					var newArrayGraph = [];
					for(let i=0;i<data.length-removeCount;i++){
						newArrayGraph.push(data[i]);	
					}
					this.graphList = newArrayGraph;
					this.firstGraph=newArrayGraph[0];
					this.lastGraph=newArrayGraph[newArrayGraph.length-1];

					this.disablePrevButtonGraph=false;
					listener.unsubscribe();
				});

			}

			prevGraphPage(){
				let listener=this.graphService.prevGraphData(this.firstGraph).snapshotChanges().pipe(
					map(actions => actions.map((a:any) => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					}))
					).subscribe(data => {
						this.graphList = data;
						this.firstGraph=data[0];
						this.lastGraph=data[data.length-1];
						this.disableNextButtonGraph=false;
						if(this.mainFirstGraph.title===this.firstGraph.title){
							this.disablePrevButtonGraph=true;
						}
						listener.unsubscribe();
					});
				}
				// end graph

				// infograph

				getInfographs(){
					let listener = this.graphService.searchInfograph(this.parm).snapshotChanges().pipe(
						map(actions => actions.map(a => {
							const data = a.payload.doc.data();
							const id = a.payload.doc.id;
							return { id, ...data };
						}))).subscribe(data=>{
						this.infographList=data;
						if(data.length==0){
							this.emptyInfograph=true;
						}
						this.firstInfograph=this.infographList[0];
						this.mainFirstInfograph=this.infographList[0];
						this.lastInfograph=this.infographList[this.infographList.length-1];

						//check for next item
						if(this.infographList.length==10){
							var myNextlistener=this.graphService.nextInfographDataOne(this.lastGraph).snapshotChanges().subscribe((a:any)=>{
								if(a.length!=0){
									this.disableNextButtonInfograph=false;
								}else{
									this.disableNextButtonInfograph=true;
								}
								myNextlistener.unsubscribe();
							});
						}else{
							this.disableNextButtonInfograph=true;
						}

						listener.unsubscribe();
					});
					}

					nextInfographPage(){

						let listener=this.graphService.nextInfographData(this.lastInfograph).snapshotChanges().pipe(
							map(actions => actions.map((a:any) => {
								const data = a.payload.doc.data();
								const id = a.payload.doc.id;
								return { id, ...data };
							}))
							).subscribe(data => {
								this.disableNextButtonInfograph = true;
								var removeCount = 0;
								if (data.length == 11) {
									removeCount = 1;
									this.disableNextButtonInfograph = false;
								}
								var newArrayGraph = [];
								for(let i=0;i<data.length-removeCount;i++){
									newArrayGraph.push(data[i]);	
								}
								this.infographList = newArrayGraph;
								this.firstInfograph=newArrayGraph[0];
								this.lastInfograph=newArrayGraph[newArrayGraph.length-1];

								this.disablePrevButtonInfograph=false;
								listener.unsubscribe();
							});

						}

						prevInfographPage(){
							let listener=this.graphService.prevInfographData(this.firstInfograph).snapshotChanges().pipe(
								map(actions => actions.map((a:any) => {
									const data = a.payload.doc.data();
									const id = a.payload.doc.id;
									return { id, ...data };
								}))
								).subscribe(data => {
									this.infographList = data;
									this.firstInfograph=data[0];
									this.lastInfograph=data[data.length-1];
									this.disableNextButtonInfograph=false;
									if(this.mainFirstInfograph.title===this.firstInfograph.title){
										this.disablePrevButtonInfograph=true;
									}
									listener.unsubscribe();
								});
							}
							// end infograph

							getResearches(){
								let listener = this.researchService.searchResearch(this.parm).snapshotChanges().pipe(
									map(actions => actions.map(a => {
										const data = a.payload.doc.data();
										const id = a.payload.doc.id;
										return { id, ...data };
									}))).subscribe(data=>{
									this.researchList=data;
									if(data.length==0){
										this.emptyResearch=true;
									}
									
									this.firstResearch=this.researchList[0];
									this.mainFirstResearch=this.researchList[0];
									this.lastResearch=this.researchList[this.researchList.length-1];

									//check for next item
									if(this.researchList.length==10){
										var myNextlistener=this.researchService.nextResearchDataOne(this.lastResearch).snapshotChanges().subscribe((a:any)=>{
											if(a.length!=0){
												this.disableNextButtonResearch=false;
											}else{
												this.disableNextButtonResearch=true;
											}
											myNextlistener.unsubscribe();
										});
									}else{
										this.disableNextButtonResearch=true;
									}
									listener.unsubscribe();
								});

								}

								nextResearchPage(){
									
									let listener=this.researchService.nextResearchData(this.lastResearch).snapshotChanges().pipe(
										map(actions => actions.map((a:any) => {
											const data = a.payload.doc.data();
											const id = a.payload.doc.id;
											return { id, ...data };
										}))
										).subscribe(data => {
											this.disableNextButtonResearch = true;
											var removeCount = 0;
											if (data.length == 11) {
												removeCount = 1;
												this.disableNextButtonResearch = false;
											}
											var newArrayResearcher = [];
											for(let i=0;i<data.length-removeCount;i++){
												newArrayResearcher.push(data[i]);	
											}
											this.researchList = newArrayResearcher;
											this.firstResearch=newArrayResearcher[0];
											this.lastResearch=newArrayResearcher[newArrayResearcher.length-1];

											this.disablePrevButtonResearch=false;
											listener.unsubscribe();
										});

									}

									prevResearchPage(){
										let listener=this.researchService.prevResearchData(this.firstResearch).snapshotChanges().pipe(
											map(actions => actions.map((a:any) => {
												const data = a.payload.doc.data();
												const id = a.payload.doc.id;
												return { id, ...data };
											}))
											).subscribe(data => {
												this.researchList = data;
												this.firstResearch=data[0];
												this.lastResearch=data[data.length-1];
												this.disableNextButtonResearch=false;
												if(this.mainFirstResearch.title===this.firstResearch.title){
													this.disablePrevButtonResearch=true;
												}
												listener.unsubscribe();
											});
										}







									}
