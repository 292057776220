import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { map } from 'rxjs/operators';
import {ResearchService} from '../../shared/services/research.service';
import {Research} from '../../shared/models/research';

@Component({
	selector: 'app-frontend-research-list',
	templateUrl: './frontend-research-list.component.html',
	styleUrls: ['./frontend-research-list.component.css']
})
export class FrontendResearchListComponent implements OnInit {

	constructor(private rout:ActivatedRoute, private researchService:ResearchService) { }
	category:any;
	researchList:any;
	researchListAll:any;
	selectType='истражувања';
	firstResearch:any;
	mainFirstResearch:any;
	lastResearch:any;
	disableNextButton:any;
	disablePrevButton=true;
	emptyResearch=false;
	
	async ngOnInit(){
		this.category = this.rout.snapshot.paramMap.get('category');
		this.getAllR();

	}

	async getAllR(){
		this.researchListAll=await this.researchService.filterCategoryList(this.category);
		this.researchList=this.researchListAll.map((a:any)=>{
			const data = a.data;
				const id = a.id;
				return { id, ...data };
			});
			this.firstResearch=this.researchList[0];
			this.mainFirstResearch=this.researchList[0];
			this.lastResearch=this.researchList[this.researchList.length-1];
			if(this.researchList.length==0){
			this.emptyResearch=true;
		}

			if(this.researchList.length==10){
				var myNextlistener=this.researchService.filterCategoryNextOne(this.category,this.lastResearch).snapshotChanges().subscribe(a=>{
				if(a.length!=0){
					this.disableNextButton=false;
				}else{
					this.disableNextButton=true;
				}
				myNextlistener.unsubscribe();
			});
			}else{
				this.disableNextButton=true;
			}
			

	}

	nextResearchPage(){
			
			let listener=this.researchService.filterCategoryNext(this.category,this.lastResearch).snapshotChanges().pipe(
				map(actions => actions.map((a:any) => {
					const data = a.payload.doc.data();
					const id = a.payload.doc.id;
					return { id, ...data };
				}))
				).subscribe(data => {
					this.disableNextButton = true;
					var removeCount = 0;
					if (data.length == 11) {
						removeCount = 1;
						this.disableNextButton = false;
					}
					var newArrayResearcher = [];
					for(let i=0;i<data.length-removeCount;i++){
						newArrayResearcher.push(data[i]);	
					}
					this.researchList = newArrayResearcher;
					this.firstResearch=newArrayResearcher[0];
					this.lastResearch=newArrayResearcher[newArrayResearcher.length-1];

					this.disablePrevButton=false;
					listener.unsubscribe();
				});

			}

			prevResearchPage(){
				let listener=this.researchService.filterCategoryPrev(this.category,this.firstResearch).snapshotChanges().pipe(
					map(actions => actions.map((a:any) => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					}))
					).subscribe(data => {
						this.researchList = data;
						this.firstResearch=data[0];
						this.lastResearch=data[data.length-1];
						this.disableNextButton=false;
						if(this.mainFirstResearch.title===this.firstResearch.title){
							this.disablePrevButton=true;
						}
						listener.unsubscribe();
					});
				}


}
