<div class="container footer">
  <div class="row">
    <div class="col-md-2">
      <div class="reactor"></div>
    </div>
    <div class="col-md-10">
      <div class="text">
        Родов Реактор е креиран и одржуван од страна на <a href="https://reactor.org.mk">Реактор - Истражување во акција</a>, во рамки на програмата за Родова еднаквост. Податоците содржани на овој сајт се собирани од различни извори, кои се сумирани во рубриките "Истражувања" и кои се наведени кај секој од графиците.</div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2">
      <div class="osf"></div>
    </div>
    <div class="col-md-10">
      <div class="text">
        Родов Реактор е подджан преку Институционален грант од <a href="www.opensocietyfoundations.org/about/programs/think-tank-fund" target="blank">Think Tank Fund</a> на <a href="https://www.opensocietyfoundations.org/" target="blank">Open Society Foundations</a>.</div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2">
      <div class="usaid"></div>
      <div class="foom"></div>
    </div>
    <div class="col-md-10">
      <div class="text">
        Родов Реактор е поддржан преку проектот на <a href="https://www.usaid.gov/north-macedonia" target="blank">USAID</a> за Граѓанско општество, имплементиран од страна на <a href="https://fosm.mk/en/" target="blank">Фондација Отворено општество - Македонија</a>. Информациите објавени на оваа веб-страна не се официјални информации на Владата на САД и не ги претставуваат погледите и позициите на <a href="https://www.usaid.gov/where-we-work/europe-and-eurasia/macedonia" target="blank">Агенцијата на Соединетите Американски Држави за меѓународен развој или на Владата на САД</a>.</div>
    </div>
  </div>
  <div class="row">
  <div class="col-md-12">
  <div class="cc">
  	<img src="https://licensebuttons.net/l/by-nc-nd/3.0/88x31.png">
  	<div class="licence">Ова дело е лиценцирано под лиценца
  	<a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/3.0/deed.mk" target="_blank">Creative Commons -Наведи извор, без комерцијализација и без измени</a></div>
  	<div class="footer-deco-right"></div>
</div>
  </div>
</div></div>
