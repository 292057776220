import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { map } from 'rxjs/operators';
import {GraphService} from '../../shared/services/graph.service';
import {Graph} from '../../shared/models/graph';
@Component({
	selector: 'app-frontend-graph-list',
	templateUrl: './frontend-graph-list.component.html',
	styleUrls: ['./frontend-graph-list.component.css']
})
export class FrontendGraphListComponent implements OnInit {

	constructor(private rout:ActivatedRoute, private graphService:GraphService) { }
	category:any;
	graphList:any;
	graphListAll:any;
	selectType:any;
	firstGraph:any;
	mainFirstGraph:any;
	lastGraph:any;
	disableNextButton:any;
	disablePrevButton=true;
	emptyGraph=false;
	
	async ngOnInit(){
		this.category = this.rout.snapshot.paramMap.get('category');
		this.getAllGraph();

	}

	async getAllGraph(){
		this.graphListAll=await this.graphService.filterCategoryList(this.category);
		this.graphList=this.graphListAll.map((a:any)=>{
			const data = a.data;
				const id = a.id;
				return { id, ...data };
			});
		this.selectType=this.graphList[0].graphic_type_view;
		this.firstGraph=this.graphList[0];
		this.mainFirstGraph=this.graphList[0];
		this.lastGraph=this.graphList[this.graphList.length-1];
		if(this.graphList.length==0){
			this.emptyGraph=true;
		}
		//check for next item
		if(this.graphList.length==10){
			var myNextlistener=this.graphService.nextCatGraphDataOne(this.category,this.lastGraph).snapshotChanges().subscribe((a:any)=>{
				if(a.length!=0){
					this.disableNextButton=false;
				}else{
					this.disableNextButton=true;
				}
				myNextlistener.unsubscribe();
			});
		}else{
			this.disableNextButton=true;
		}

	}

	nextGraphPage(){

		let listener=this.graphService.nextCatGraphData(this.category,this.lastGraph).snapshotChanges().pipe(
			map(actions => actions.map((a:any) => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))
			).subscribe(data => {
				this.disableNextButton = true;
				var removeCount = 0;
				if (data.length == 11) {
					removeCount = 1;
					this.disableNextButton = false;
				}
				var newArrayResearcher = [];
				for(let i=0;i<data.length-removeCount;i++){
					newArrayResearcher.push(data[i]);	
				}
				this.graphList = newArrayResearcher;
				this.firstGraph=newArrayResearcher[0];
				this.lastGraph=newArrayResearcher[newArrayResearcher.length-1];

				this.disablePrevButton=false;
				listener.unsubscribe();
			});

		}

		prevGraphPage(){
			let listener=this.graphService.prevCatGraphData(this.category,this.firstGraph).snapshotChanges().pipe(
				map(actions => actions.map((a:any) => {

					const data = a.payload.doc.data();
					const id = a.payload.doc.id;
					return { id, ...data };
				}))
				).subscribe(data => {
					this.graphList = data;
					this.firstGraph=data[0];
					this.lastGraph=data[data.length-1];
					this.disableNextButton=false;
					if(this.mainFirstGraph.title===this.firstGraph.title){
						this.disablePrevButton=true;
					}
					listener.unsubscribe();
				});
			}


		}
