import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, ViewChildren, QueryList } from '@angular/core';

@Component({
	selector: 'app-frontend-sidebar',
	templateUrl: './frontend-sidebar.component.html',
	styleUrls: ['./frontend-sidebar.component.css']
})
export class FrontendSidebarComponent implements OnInit {
	@Input() category:string='';
	@Input() selectType:string='';
	@ViewChildren("toggles") toggles: any;
	toggle=false;
	id:any;
	constructor() { }
	ngOnInit(): void {

	}
	toggleSidebar(id:any){
		let value=null;
		this.toggles.forEach((item:any)=>{
			if(item.nativeElement.getAttribute('data-id') === id){
				value = item.nativeElement.value;
				if(value==0){
					this.toggle=true;
					this.id=id;
				}else{
					this.toggle=false;
				}
			}
		});
		return value;
	}


}
