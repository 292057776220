<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
    <span class="nav-inside"><a href="dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
        <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>
<div class="container">
  <div class="dashboard_title">Ажурирајте</div>
  <form [formGroup]="researchForm" (ngSubmit)="onSubmit(researchForm)">
    <div class="form-group">
      <label for="researchTitle">Наслов</label>
      <input type="text" class="form-control" id="researchTitle" formControlName="title" aria-describedby="emailHelp" placeholder="Внесете наслов" required [ngClass]="{ 'is-invalid': submitted && researchForm.controls.title.errors }" />
      <div *ngIf="submitted && researchForm.controls.title.errors" class="invalid-feedback">
        <div *ngIf="researchForm.controls.title.errors.required"><span>{{'Ова поле е задолжително'}}</span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="abstract">Абстракт</label>
      <angular-editor id="editorAbstract" [placeholder]="'Внесете абстракт'" formControlName="abstract" [ngClass]="{ 'is-invalid':  submitted && researchForm.controls.abstract.invalid }" required [config]="config"></angular-editor>
    </div>
    <div class="form-group">
      <label for="methodology">Методологија</label>
      <angular-editor [placeholder]="'Внесете методологија'" formControlName="methodology" [ngClass]="{ 'is-invalid':  submitted && researchForm.controls.methodology.invalid }" required [config]="config" id="editorMethodolgy"></angular-editor>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="researchCategory">Категории</label></div>
      <div class="col-md-8">
        <div class="category_box">
          <div *ngFor="let category of categories().controls">
            <input type="checkbox" name="categories" [value]="category.value.name" (change)="isCategorySelected($event,category.value.name)" [checked]="category.value.ischecked" />
            {{category.value.name}}
          </div>
        </div>
      </div>
      <div class="col-md-1"><button class="chart_add" placement="top" ngbTooltip="Додади категорија" (click)="addCategory()">+</button></div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="researchResearchers">Истражувачи</label></div>
      <div class="col-md-8">
        <div class="category_box">
          <div *ngFor="let res of researchers().controls">
            <input type="checkbox" name="researchers" [value]="res.value.name" (change)="isResearcherSelected($event,res.value.name)" [checked]="res.value.ischecked" />
            {{res.value.name}}
          </div>
        </div>
      </div>
      <div class="col-md-1"><button class="chart_add" placement="top" ngbTooltip="Додади категорија" (click)="addResearcher()">+</button></div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="myFile">Слика за истражувањето </label></div>
      <div class="col-md-4" *ngIf="image_path"><img [src]="image_path" width="230px" height="150px" />
        <p *ngIf="uploadImageSuccessful==1" class="respo succ">Upload successfull</p> <br />
        <p *ngIf="uploadImageSuccessful==0" class="respo fail">Upload not completed, try again</p> <br />
      </div>
      <div class="col-md-5"><input type="file" name="file" (change)="fileEventImage($event)" /></div>
    </div>
    <div class="form-group">
      <label for="researchTitle">Година на издавање</label>
      <input type="text" class="form-control" id="researchYearPublished" formControlName="year_published" aria-describedby="emailHelp" placeholder="Внесете година" [ngClass]="{ 'is-invalid': submitted && researchForm.controls.year_published.invalid }" required>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="myPdf">Пдф за истражувањето</label></div>
      <div class="col-md-4" *ngIf="pdf_path"><a [href]="pdf_path" target="_blank">Клик за линк до документот</a>
        <p *ngIf="uploadPdfSuccessful==1" class="respo succ">Upload successfull</p> <br />
        <p *ngIf="uploadPdfSuccessful==0" class="respo fail">Upload not completed, try again</p> <br />
      </div>
      <div class="col-md-5"><input type="file" name="file" (change)="fileEventPdf($event)" /></div>
    </div>
    <div class="container" style="margin-bottom: 50px">
      <div class="row">
        <div class="col-md-9">
          <button type="submit" class="btn btn-primary">Ажурирај</button>
        </div>
        <div class="col-md-3">
          <button class="btn btn-primary" (click)="deleteResearch()">Избриши (веднаш)</button>
        </div>
      </div>
    </div>
  </form>
</div>
