<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
      <span class="nav-inside"><a href="/dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
        <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>

	<div class="container">
	<div class="dashboard_title">Додади нова категорија</div>
		<form #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm)">
			<div class="form-group">
				<label for="categoryName">Име</label>
				<input type="text" class="form-control" id="categoryrName" name="name" 
				[(ngModel)]="category.name" #name="ngModel" aria-describedby="emailHelp" placeholder="Внесете име" [ngClass]="{ 'is-invalid': categoryForm.submitted && name.invalid }" required>
			</div>
			<div class="form-group">
				<label for="description">Опис</label>
				<angular-editor [placeholder]="'Внесете опис'" [(ngModel)]="category.description" name="description" #description="ngModel" [ngClass]="{ 'is-invalid':  categoryForm.submitted && description.invalid }" required [config]="config"></angular-editor>
			</div>
			<button type="submit" class="btn btn-primary">Додадете категорија</button>
		</form>
	</div>