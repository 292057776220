import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardResearcherAddComponent } from './components/dashboard-researcher-add/dashboard-researcher-add.component';
import { DashboardResearcherListComponent } from './components/dashboard-researcher-list/dashboard-researcher-list.component';
import { DashboardResearcherUpdateComponent } from './components/dashboard-researcher-update/dashboard-researcher-update.component';
import { DashboardCategoryAddComponent } from './components/dashboard-category-add/dashboard-category-add.component';
import { DashboardCategoryListComponent } from './components/dashboard-category-list/dashboard-category-list.component';
import { DashboardCategoryUpdateComponent } from './components/dashboard-category-update/dashboard-category-update.component';
import { DashboardGraphAddComponent } from './components/dashboard-graph-add/dashboard-graph-add.component';
import { DashboardGraphListComponent } from './components/dashboard-graph-list/dashboard-graph-list.component';
import { DashboardGraphUpdateComponent } from './components/dashboard-graph-update/dashboard-graph-update.component';
import { DashboardResearchAddComponent } from './components/dashboard-research-add/dashboard-research-add.component';
import { DashboardResearchListComponent } from './components/dashboard-research-list/dashboard-research-list.component';
import { DashboardResearchUpdateComponent } from './components/dashboard-research-update/dashboard-research-update.component';
import { DashboardPageComponent } from './components/dashboard-page/dashboard-page.component';

import { FrontendHeaderComponent } from './components/frontend-header/frontend-header.component';
import { FrontendHomeComponent } from './components/frontend-home/frontend-home.component';
import { FrontendCategoryPageComponent } from './components/frontend-category-page/frontend-category-page.component';
import { FrontendResearchListComponent } from './components/frontend-research-list/frontend-research-list.component';
import { FrontendResearchPageComponent } from './components/frontend-research-page/frontend-research-page.component';
import { FrontendGraphListComponent } from './components/frontend-graph-list/frontend-graph-list.component';
import { FrontendGraphPageComponent } from './components/frontend-graph-page/frontend-graph-page.component';
import { FrontendInfographicListComponent } from './components/frontend-infographic-list/frontend-infographic-list.component';
import { FrontendInfographicPageComponent } from './components/frontend-infographic-page/frontend-infographic-page.component';
import { FrontendGraphAllComponent } from './components/frontend-graph-all/frontend-graph-all.component';
import { FrontendInfographicAllComponent } from './components/frontend-infographic-all/frontend-infographic-all.component';
import { FrontendResearchAllComponent } from './components/frontend-research-all/frontend-research-all.component';
import { FrontendPageComponent } from './components/frontend-page/frontend-page.component';
import { FrontendSearchComponent } from './components/frontend-search/frontend-search.component';


import { AuthGuard } from './shared/guard/auth.guard';
const routes: Routes = [
// { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
// { path: '', redirectTo: '/dashboard', pathMatch: 'full' },

{ path: 'sign-in', component: SignInComponent },
{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },

{ path: 'researcher-add', component: DashboardResearcherAddComponent, canActivate: [AuthGuard] },
{ path: 'researcher-list', component: DashboardResearcherListComponent, canActivate: [AuthGuard] },
{ path: 'researcher-list/:id', component: DashboardResearcherUpdateComponent, canActivate: [AuthGuard] },

{ path: 'category-add', component: DashboardCategoryAddComponent, canActivate: [AuthGuard] },
{ path: 'category-list', component: DashboardCategoryListComponent, canActivate: [AuthGuard] },
{ path: 'category-list/:id', component: DashboardCategoryUpdateComponent, canActivate: [AuthGuard] },

{ path: 'graph-add', component: DashboardGraphAddComponent, canActivate: [AuthGuard] },
{ path: 'graph-list', component: DashboardGraphListComponent, canActivate: [AuthGuard] },
{ path: 'graph-list/:id', component: DashboardGraphUpdateComponent, canActivate: [AuthGuard] },

{ path: 'research-add', component: DashboardResearchAddComponent, canActivate: [AuthGuard] },
{ path: 'research-list', component: DashboardResearchListComponent, canActivate: [AuthGuard] },
{ path: 'research-list/:id', component: DashboardResearchUpdateComponent, canActivate: [AuthGuard] },
{ path: 'dashboard/:page',component:DashboardPageComponent},
{ path: 'category/:category',component:FrontendCategoryPageComponent},

{ path: 'category/:category/research-list',component:FrontendResearchListComponent},
{ path: 'category/:category/research-list/:id',component:FrontendResearchPageComponent},

{ path: 'category/:category/graph-list',component:FrontendGraphListComponent},
{ path: 'category/:category/graph-list/:id',component:FrontendGraphPageComponent},
{ path: 'category/:category/infographic-list',component:FrontendInfographicListComponent},
{ path: 'category/:category/infographic-list/:id',component:FrontendInfographicPageComponent},


{ path: 'graph-all',component:FrontendGraphAllComponent},
{ path: 'infographic-all',component:FrontendInfographicAllComponent},
{ path: 'research-all',component:FrontendResearchAllComponent},
{ path: 'page/:page',component:FrontendPageComponent},
{ path: 'search/:searchtext',component:FrontendSearchComponent},


// { path: '**', component: NotFoundComponent}
{ path: '', component: FrontendHomeComponent},



];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}