<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
    <span class="nav-inside"><a href="/dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>
<form [formGroup]="graphForm" (ngSubmit)="onSubmit(graphForm)">
  <div class="container">
    <div class="dashboard_title">Ажурирај</div>
    <div class="form-group row">
      <div class="col-md-3"><label for="graphTitle">Име</label></div>
      <div class="col-md-9"><input type="text" class="form-control" id="graphTitle" formControlName="title" required [ngClass]="{ 'is-invalid': submitted && graphForm.controls.title.errors }" />
        <div *ngIf="submitted && graphForm.controls.title.errors" class="invalid-feedback">
          <div *ngIf="graphForm.controls.title.errors.required"><span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="description">Опис</label></div>
      <div class="col-md-9">
        <angular-editor [placeholder]="'Внесете опис'" formControlName="description" required [config]="config" [ngClass]="{ 'is-invalid': submitted && graphForm.controls.description.errors }">
        </angular-editor>
        <div *ngIf="submitted && graphForm.controls.description.errors" class="invalid-feedback">
          <div *ngIf="graphForm.controls.description.errors.required"><span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="graphResearchesRes">Истражувања</label></div>
      <div class="col-md-8">
        <div class="category_box">
          <div *ngFor="let res of researches().controls">
            <input type="checkbox" FormControlName="name" [checked]="res.value.ischecked" (change)="isResearchSelected($event,res.value.name)">{{res.value.name}}
          </div>
        </div>
      </div>
      <div class="col-md-1"><button class="chart_add" placement="top" ngbTooltip="Додади категорија" (click)="addCategory()">+</button></div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="graphResearchesCat">Категории</label></div>
      <div class="col-md-8">
        <div class="category_box">
          <div *ngFor="let category of categories().controls">
            <input type="checkbox" FormControlName="name" [checked]="category.value.ischecked" (change)="isCategorySelected($event,category.value.name)">{{category.value.name}}
          </div>
        </div>
      </div>
      <div class="col-md-1"><button class="chart_add" placement="top" ngbTooltip="Додади категорија" (click)="addCategory()">+</button></div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="graphSource">Извор</label></div>
      <div class="col-md-9"><input type="text" class="form-control" id="graphSource" formControlName="source" required [ngClass]="{ 'is-invalid': submitted && graphForm.controls.source.errors }" />
        <div *ngIf="submitted && graphForm.controls.source.errors" class="invalid-feedback">
          <div *ngIf="graphForm.controls.source.errors.required"><span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="graphSourceLink">Линк до изворот</label></div>
      <div class="col-md-9"><input type="text" class="form-control" id="graphSourceLink" formControlName="source_link" required [ngClass]="{ 'is-invalid': submitted && graphForm.controls.source_link.errors }" />
        <div *ngIf="submitted && graphForm.controls.source_link.errors" class="invalid-feedback">
          <div *ngIf="graphForm.controls.source_link.errors.required"><span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3">
        <label for="myFile">Слика за графикот (.png/.jpg)</label>
      </div>
      <div class="col-md-4" *ngIf="image_path">
        <img [src]="image_path" width="230px" height="150px" />
        <p *ngIf="uploadImageSuccessful==1" class="respo succ">Upload successfull</p> <br />
        <p *ngIf="uploadImageSuccessful==0" class="respo fail">Upload not completed, try again</p> <br />
      </div>
      <div class="col-md-5">
        <input type="file" FormControl="file" (change)="fileEvent($event)" />
        <div *ngIf="submitted && !image_path" class=" mandatory_line">
          <div *ngIf="submitted && !image_path">
            <span>{{'Ова поле е задолжително'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isinfographic">
    <div class="container_fluid" style="background: #ced3e7;padding: 10px 0px; margin:20px 0px">
      <div class="container">Креирај визуелизација на графикот
      </div>
    </div>
    <div class="container">
      <div class="form-group row">
        <div class="col-md-3"><label for="chartTitle">Наслов</label></div>
        <div class="col-md-9"><input type="text" class="form-control" id="chartTitle" formControlName="chart_title" required [ngClass]="{ 'is-invalid': submitted && graphForm.controls.chart_title.errors }" />
          <div *ngIf="submitted && graphForm.controls.chart_title.errors" class="invalid-feedback">
            <div *ngIf="graphForm.controls.chart_title.errors.required"><span>{{'Ова поле е задолжително'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-3"><label for="chartSubtitle">Година на објавени податоци (subtitle)</label></div>
        <div class="col-md-9"><input type="text" class="form-control" id="chartSubtitle" formControlName="chart_subtitle" required [ngClass]="{ 'is-invalid': submitted && graphForm.controls.chart_subtitle.errors }" />
          <div *ngIf="submitted && graphForm.controls.chart_subtitle.errors" class="invalid-feedback">
            <div *ngIf="graphForm.controls.chart_subtitle.errors.required"><span>{{'Ова поле е задолжително'}}</span>
            </div>
          </div><span class="chart_help"> ( пр: 2010 или 2010 - 2015 )</span>
        </div>
      </div>
      <div class="form-group row" *ngIf="chart_series_show">
        <div class="col-md-3"><label for="chartXaxis">Хоризонтални категории (x-Axis)</label></div>
        <div class="col-md-9"><input type="text" class="form-control" id="chartXaxis" formControlName="chart_xaxis" />
          <span class="chart_help"> ( пр: по години = 2000,2001,2002, по возраст = 15-24,25-34,35-44, во полето внесувате само броеви)</span></div>
      </div>
      <div class="form-group row">
        <div class="col-md-3"><label for="chartYaxis">Вертикално (y-Axis)</label></div>
        <div class="col-md-9"> <input type="text" class="form-control" id="chartYaxis" formControlName="chart_yaxis" required [ngClass]="{ 'is-invalid': submitted && graphForm.controls.chart_yaxis.errors }" />
          <div *ngIf="submitted && graphForm.controls.chart_yaxis.errors" class="invalid-feedback">
            <div *ngIf="graphForm.controls.chart_yaxis.errors.required"><span>{{'Ова поле е задолжително'}}</span>
            </div>
          </div><span class="chart_help"> ( пр: проценти или број или часови и минути )</span>
        </div>
      </div>
      <div class="form-group " *ngIf="chart_series_show">
        <div class="row" style="padding:10px 0px; align-items: center;">
          <div class="col-md-7"></div>
          <div class="col-md-4" style="text-align: right;">Додади серија - визуелизација </div>
          <div class="col-md-1"><button class="chart_add" (click)="addSeries()">+</button></div>
        </div>
        <div formArrayName="chart_series" class="">
          <div *ngFor="let chart_seriesForm of chart_series().controls; let index='index'">
            <div [formGroupName]="index">
              <div class="form-group row">
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3">
                      <div style="color: white;background-color: #585e87;text-align: center;">{{index+1}} </div>
                    </div>
                    <div class="col-md-8"><label for="cat_name"> Категорија :</label></div>
                  </div>
                </div>
                <div class="col-md-9"><input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && chart_series().controls[index].get('name')?.errors }" />
                  <div *ngIf="submitted && chart_series().controls[index].get('name')?.errors?.required" class="invalid-feedback mandatory_line">
                    <div *ngIf="chart_series().controls[index].get('name')?.errors?.required"><span>{{'Ова поле е задолжително'}}</span>
                    </div>
                  </div>
                  <span class="chart_help">(пр: жени)</span>
                </div>
              </div>
              <div class="form-group row" *ngIf="show_stack">
                <div class="col-md-3"><label for="cat_data">Групиран stack column :</label></div>
                <div class="col-md-9"><input type="text" formControlName="stack" class="form-control" [ngClass]="{ 'is-invalid': submitted && chart_series().controls[index].get('data')?.errors }" />
                  <div *ngIf="submitted && chart_series().controls[index].get('stack')?.errors?.required" class="invalid-feedback mandatory_line">
                    <div *ngIf="chart_series().controls[index].get('stack')?.errors?.required"><span>{{'Ова поле е задолжително'}}</span>
                    </div>
                  </div>
                  <span class="chart_help">( пр:  жени ) </span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-3"><label for="cat_data">Податоци :</label></div>
                <div class="col-md-9"><input type="text" formControlName="data" class="form-control" [ngClass]="{ 'is-invalid': submitted && chart_series().controls[index].get('data')?.errors }" />
                  <div *ngIf="submitted && chart_series().controls[index].get('data')?.errors?.required" class="invalid-feedback mandatory_line">
                    <div *ngIf="chart_series().controls[index].get('data')?.errors?.required"><span>{{'Ова поле е задолжително'}}</span>
                    </div>
                  </div>
                  <span class="chart_help">( пр: 25,55,66 - овој број на категории е еднаков на бројот на x-Axis ) </span>
                </div>
              </div>


              <div class="form-group row">
                <div class="col-md-3"><label for="cat_color">Боја на бар :</label></div>
                <div class="col-md-8"><input type="text" formControlName="color" class="form-control" [ngClass]="{ 'is-invalid': submitted && chart_series().controls[index].get('color')?.errors }" />
                  <div *ngIf="submitted && chart_series().controls[index].get('color')?.errors?.required" class="invalid-feedback mandatory_line">
                    <div *ngIf="chart_series().controls[index].get('color')?.errors?.required"><span>{{'Ова поле е задолжително'}}</span>
                    </div>
                  </div>
                  <span class="chart_help">( пр: #58D68D )</span>
                </div>
                <div class="col-md-1"><button class="chart_add" placement="top" ngbTooltip="Избриши ја серијата" (click)="removeSeries(index)">-</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- new  -->
      <!-- column_bar_line-->
      <div formArrayName="chart_series_combined" *ngIf="chart_combined_series_column && !chart_series_show">
        <div *ngFor="let serie of chart_series_combined().controls; let serieIndex=index ">
          <div [formGroupName]="serieIndex">
            <div class="row">
              <div class="col-md-12">Тука можеш да составиш колона и линија, лента и линија, линијата може да биде со пр, броеви за средна вредност или нормална вредност, само не ставајте повеќе гарфика од 2 </div>
              <div class="col-md-11" style="padding: 20px 0px;text-align: right;">Додади серија на визуелизација од тип </div>
              <div class="col-md-1"><span class="chart_add" (click)="addNewColumnBarLine(serieIndex)">+</span></div>
            </div>
            <div formArrayName="column_bar_line">
              <div *ngFor="let data of getColumnBarLine(serieIndex).controls; let dataIndex=index ">
                <div [formGroupName]="dataIndex">
                  <div class="form-group row">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="row">
                          <div class="col-md-3">
                            <div style="color: white;background-color: #585e87;text-align: center;">{{dataIndex+1}} </div>
                          </div>
                          <div class="col-md-9"><label for="cat_name"> Име на категорија:</label></div>
                        </div>
                      </div>
                      <div class="col-md-8"><input type="text" formControlName="name" class="form-control" /><span class="chart_help">(пр: жени)</span> </div>
                      <div class="col-md-4"><label for="cat_data">Податоци :</label></div>
                      <div class="col-md-8"><input type="text" formControlName="data" class="form-control" />
                        <span class="chart_help">( пр: 25,55,66 - овој број на категории е еднаков на бројот на x-Axis ) </span>
                      </div>
                      <div class="col-md-4"><label for="cat_data">Боја :</label></div>
                      <div class="col-md-8"><input type="text" formControlName="color" class="form-control" />
                        <span class="chart_help">( пр: #58D68D ) </span>
                      </div>
                      <div class="col-md-4"><label for="cat_type">Тип :</label></div>
                      <div class="col-md-7"><input type="text" formControlName="type" class="form-control" />
                        <span class="chart_help">( пр: column or line or bar ) </span>
                      </div>
                      <div class="col-md-1"><span class="chart_add" placement="top" ngbTooltip="Избриши ја серијата" (click)="removeColumnBarLine(serieIndex,dataIndex)">-</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end column_bar_line -->
      <!-- pie chart -->
      <div formArrayName="chart_series_combined" *ngIf="chart_combined_series_pie==true && !chart_series_show">
        <div *ngFor="let serie of chart_series_combined()?.controls; let serieIndex=index ">
          <div [formGroupName]="serieIndex">
            <div formArrayName="pie">
              <div *ngFor="let pie of getPie(serieIndex).controls; let dataIndex=index ">
                <div [formGroupName]="dataIndex">
                  <div class="row">
                    <div class="col-md-11" style="padding: 20px 0px;text-align: right;">Додади серија на визуелизација, состави пита од 100% </div>
                    <div class="col-md-1">
                      <span class="chart_add" (click)="addNewPieDataInData(serieIndex, dataIndex)">+</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-4">
                      <div class="row">
                        <div class="col-md-3">
                          <div style="color: white;background-color: #585e87;text-align: center;">{{dataIndex+1}} </div>
                        </div>
                        <div class="col-md-9"><label for="cat_name"> Име на категорија:</label></div>
                      </div>
                    </div>
                    <div class="col-md-7"><input type="text" formControlName="name" class="form-control" /></div>
                  </div>
                  <div formArrayName="data">
                    <div *ngFor="let dat of getPieDataInData(serieIndex,dataIndex).controls; let datIndex=index ">
                      <div [formGroupName]="datIndex">
                        <div class="form-group row">
                          <div class="col-md-4"><label for="cat_name">Име:</label></div>
                          <div class="col-md-7"><input type="text" formControlName="name" class="form-control" /></div>
                        </div>
                        <div class="form-group row">
                          <div class="col-md-4"><label for="cat_data">Процент (за да прикаже %) :</label></div>
                          <div class="col-md-7"><input type="number" formControlName="y" class="form-control" style="width:200px" /><span class="chart_help"> ( пр: 52.33 или 30 ) </span></div>
                        </div>
                        <div class="form-group row">
                          <div class="col-md-4"><label for="cat_color">Боја на бар :</label></div>
                          <div class="col-md-7"><input type="text" formControlName="color" class="form-control" style="width:200px" /><span class="chart_help">( пр: #58D68D )</span></div>
                          <div class="col-md-1"><span class="chart_add" placement="top" ngbTooltip="Избриши ја серијата" (click)="removePieDatainData(serieIndex,dataIndex,datIndex)">-</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <!--  -->
        </div>
      </div>
      <!-- end pie chart -->
      <div class="form-group row" *ngIf="chart_series_show && !chart_combined_series_column">
        <div class="col-md-3"><label for="primaryChartType">Повеќеслојност</label></div>
        <div class="col-md-9">
          <div class="form-check">
            <input class="form-check-input" type="radio" value="undefined" formControlName="chart_stack" id="chart_stack1">
            <label class="form-check-label" for="chart_stack1">
              Нормален график
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="normal" formControlName="chart_stack" id="chart_stack2">
            <label class="form-check-label" for="chart_stack2">
              Повеќеслоен
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="percent" formControlName="chart_stack" id="chart_stack3">
            <label class="form-check-label" for="chart_stack3">
              Повеќеслоен, скалирано од 100%
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" style="margin-bottom: 50px">
    <div class="row">
      <div class="col-md-9">
        <button type="submit" class="btn btn-primary">Ажурирај</button>
      </div>
      <div class="col-md-3">
        <button class="btn btn-primary" (click)="deleteGraph()">Избриши (веднаш)</button>
      </div>
    </div>
  </div>
</form>
