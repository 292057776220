import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {GraphService} from '../../shared/services/graph.service';
import {ResearchService} from '../../shared/services/research.service';
import {AuthService} from '../../shared/services/auth.service';

import { faCode, faComment, faInfo } from '@fortawesome/free-solid-svg-icons';

import {Graph} from '../../shared/models/graph';

@Component({
	selector: 'app-frontend-infographic-page',
	templateUrl: './frontend-infographic-page.component.html',
	styleUrls: ['./frontend-infographic-page.component.css']
})
export class FrontendInfographicPageComponent implements OnInit {
	embed=faCode;
	chat=faComment;
	info=faInfo
	category:any;
	title:any;
	graphData:any;
	selectType:any='инфографик';
	researchId:any;
	constructor(private rout:ActivatedRoute, private researchService:ResearchService, private graphService:GraphService, private authService:AuthService) { }
	async ngOnInit(){
		this.category = this.rout.snapshot.paramMap.get('category');
		this.title=this.rout.snapshot.paramMap.get('id');
		var listener=this.graphService.getGraphDataByName(this.title).valueChanges().subscribe(graph=>{
			this.graphData=graph;
			for(let gra of this.graphData){

//find researchid
				for(var r of gra.researches){
					if(r.ischecked==true){
						this.getResearchId(r.name);
					}
				}
			}

		});
	}

	async getResearchId(title:any){
		var id:any=await this.researchService.getOneResearchForGraph(title);
		this.researchId=id[0].id;
	}
}
