import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {GraphService} from '../../shared/services/graph.service';
import {ResearchService} from '../../shared/services/research.service';
import {AuthService} from '../../shared/services/auth.service';

import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { faCode, faComment, faInfo } from '@fortawesome/free-solid-svg-icons';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

import {Graph} from '../../shared/models/graph';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';

import { Meta, MetaDefinition } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

exporting(Highcharts);

@Component({
	selector: 'app-frontend-graph-page',
	templateUrl: './frontend-graph-page.component.html',
	styleUrls: ['./frontend-graph-page.component.css']
})
export class FrontendGraphPageComponent implements OnInit {
	embed=faCode;
	chat=faComment;
	info=faInfo
	category:any;
	id:any;
	title:any;
	desc:any;
	graphData:any;
	researchData:any;
	Highcharts: typeof Highcharts = Highcharts;
	chartOptions: Highcharts.Options={};
	stackData:any;
	closeResult:string="";
	updateFlag=false;
	oneToOneFlag=false;
	selectedType:any;
	chart_myType:any;
	selectType:any;
	combined_chart=true;
	tooltip:any;
	modal:any;
	researchId:any;
	chart_type_all:any=[{id:'line', name:'Линија'},{id:'bar',name:'Лента'},{id:'column',name:'Столб'}];

	// recaptcha
	askDataForm!:FormGroup;
	siteKey:string="6Lfwe-4gAAAAAF3T86NxLyYlGMADAlu8DwAqoteb";
	recaptcha:any;
	submitted:boolean=false;
	// end recaptcha

	// metatags
	metaurl:any;
	metaimg:any;
	//private meta!: Meta;

	constructor(private rout:ActivatedRoute, private researchService:ResearchService, private graphService:GraphService, private modalService: NgbModal, private activeModal:NgbActiveModal, private authService:AuthService, private http:HttpClient,private fb:FormBuilder, private meta:Meta) { 


	}
	async ngOnInit(){
		//  ask row data form - recaptcha

		this.askDataForm=this.fb.group({
			messageTitle:['',Validators.required],
			messageDes:['',Validators.required],
			messageEmail:['',[Validators.required, Validators.email]],
			recaptcha: ['', Validators.required]
		});

		// end row data

		

		this.category = this.rout.snapshot.paramMap.get('category');
		this.id=this.rout.snapshot.paramMap.get('id');

		


		var listener=this.graphService.getGraphDataByName(this.id).valueChanges().subscribe(graph=>{
			this.graphData=graph;

			
			this.tooltip={
				formatter: function() {
					return 	 '<b>'+this.x+'<br>'+this.series.name + '<br />'+
					this.y+'</b>';
				}
			};
			for(let gra of this.graphData){

//find researchid
				for(var r of gra.researches){
					if(r.ischecked==true){
						this.getResearchId(r.name);
					}
				}

				this.title=gra.title;
				this.desc=gra.description.slice(0,100);
				var arrayXaxis=gra.chart_xaxis.split(',');
				var newSerieData=[];
				var stackData={};
				this.selectType=gra.graphic_type_view;

				if(gra.chart_stack=='undefined'){
					stackData={
						stacking:undefined
					}
				}else{

					//console.log(gra.chart_stack);
					stackData={
						stacking:gra.chart_stack
					};
				}

				if(gra.chart_type=='chart_type_frequency'){
					for(let m of gra.chart_series){
						this.chart_myType=gra.chart_primary_type;
						this.selectedType=gra.chart_primary_type;
						newSerieData.push({name:m.name,data:m.data,color:m.color,stack:m.stack,
							type:gra.chart_primary_type,
							showInLegend: true,
							dataLabels: {
								enabled: true,
							}});
					}

				}else{
					var arrayData=[];
					for(let comb of gra.chart_series_combined){
						this.chart_myType=gra.chart_primary_type;
						this.combined_chart=false;
						console.log(comb);
						if(!comb.pie){
							for(let c of comb.column_bar_line){
								arrayData=c.data.split(',').map(function(item:any) {
									return parseInt(item, 10);
								});
								newSerieData.push({type:c.type,name:c.name,data:arrayData,color:c.color,showInLegend: true,
									dataLabels: {
										enabled: true,
									}});
							}

						}else{

							for(let c of comb.pie){
								newSerieData.push({type:'pie',name:c.name,data:c.data,color:c.color,showInLegend: true,
									allowPointSelect: true,
									center:["50%","50%"],
									size:"100%",
									dataLabels: {
										enabled: true,

									}});
								this.tooltip={ pointFormat: '{series.name}: <b>{point.percentage:f}%</b>'};

							}

							if(comb.pie.length>1){									
								if(newSerieData[0]){
									newSerieData[0].center=["30%","50%"];
									newSerieData[0].size="75%";
								}

								if(newSerieData[1]){
									newSerieData[1].center=["70%","50%"];
									newSerieData[1].size="75%";

								}
							}else{

							}
						}
					}
				}
				this.createChart(stackData,gra.chart_title,gra.chart_subtitle, arrayXaxis,gra.chart_yaxis, newSerieData, gra.source, gra.source_link);

				// metatags
		this.metaurl='https:/rodovreactor.mk/category/'+this.category+'/graph-list/'+this.id;
		this.metaimg='https:/rodovreactor.mk/assets/img/uploads/graphs/_'+this.id+'.png';
		this.meta.updateTag({ content: this.metaurl}, "property='og:url'");
		this.meta.updateTag({ content: this.title}, "property='og:title'");
		this.meta.updateTag({content: this.metaimg},"property='og:image'");
		this.meta.updateTag({ content: this.desc},"property='og:description'");

		// end metatags

		    this.meta.addTag( { name:'og:description',content:"Article Description"});



			}
			listener.unsubscribe();
		});


		
	}

	async getResearchId(title:any){
		var id:any=await this.researchService.getOneResearchForGraph(title);
		this.researchId=id[0].id;
	}


	createChart(stack:any,chart_title:any,chart_subtitle:any, chart_xaxis:any,chart_yaxis:any, chart_series_data:any, source:any, source_link:any){
		this.chartOptions = {
			chart:{
				type:this.chart_myType,
				style: {
					fontFamily: 'PT Sans'
				}
			},
			
			title: {
				text:chart_title,
				margin: 50,
				 style: {
            
            fontSize: '16px'
        },
				y:20
			},
			tooltip: this.tooltip,
			plotOptions: {
				series:stack
			},
			subtitle: {
				text: chart_subtitle,
				y:50
			},
			xAxis: {
				categories: chart_xaxis,
				//visible:true
			},

			yAxis: {
				min: 0,
				title: {
					text:chart_yaxis
				},
				gridLineWidth: 0,
				//visible:false

			},
			
			series: chart_series_data,
			
			exporting: {
				buttons: {
					contextButton: {
						menuItems: [
						'viewFullscreen', 'separator', 'downloadPNG',
						'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
						]
					},
				},
				enabled: true,
			},
			navigation: {
				buttonOptions: {
					align: 'right',
					verticalAlign: 'top',
					y: 20,

				}
			},
			credits: {
				//enabled: false,
				text:source,
				href:source_link

			},
		};
	}

	open(content:any) {
		this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return  `with: ${reason}`;
		}
	}

	checkType(event:any){
		const mytype = event.target.value;
		for(let m in this.chartOptions.series!){
			// 	console.log(mytype);

			this.chartOptions.series![m]={
				type:mytype
			}
		}
		

		this.updateFlag = true;
		this.chartOptions.chart!.type=mytype;
	}

	handleSuccessRecaptcha(response: any) {
		this.recaptcha=response;
	}



	sendMessage(data:any){
		this.submitted=true;
		if(this.askDataForm.valid){
			//console.log(data.value.messageDes);
			//var date=new Date();
			this.authService.sendSimpleCheckMail(data);

			this.modalService.dismissAll();

		}else{
			return;
		}
		
	}

}
