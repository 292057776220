<div class="container-fluid header-back">
	<div class="container">
		<div class="row">
			<div class="col-md-2">
				<a href="/dashboard"><img src="/assets/img/logo-circle.png" width="50px"></a>
			</div>
			<div class="col-md-8 center_box title" >Родов реактор</div>
			<div class="col-md-2 signOutbtn center_box">
				<span><fa-icon [icon]="signOutIcon"></fa-icon></span>
				<span  (click)="authService.SignOut()">Одјави се</span>
			</div>
		</div>
	</div>
</div>