import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {CategoryService} from '../../shared/services/category.service';
import {Category} from '../../shared/models/category';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-dashboard-category-update',
	templateUrl: './dashboard-category-update.component.html',
	styleUrls: ['./dashboard-category-update.component.css']
})
export class DashboardCategoryUpdateComponent implements OnInit {
	id:any;
	categoryData:any;
	submitted=false;
	category:Category={
		name:'',
		description:''
	};

	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "200px",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "p",
		defaultFontName: "Arsenal",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: false,
		customClasses: [
		
		{
			name: "centerTextImage",
			class: "centerTextImage",
		},
		{	name:"article_img",
			class:"article_img"
		}],
	// uploadUrl: '',
	//    uploadWithCredentials: false,	
};

constructor(private categoryService:CategoryService, private routeId:ActivatedRoute, private router:Router) { }

ngOnInit(): void {
	this.id = this.routeId.snapshot.paramMap.get('id');
	this.categoryData=this.categoryService.getCategoryData(this.id).valueChanges().subscribe(data=>{
		this.category={name:data?.name!,description:data?.description!};
		this.categoryData.unsubscribe();
	});
}

deleteCategory(){
			this.categoryService.deleteCategory(this.id);
			this.router.navigate(['/category-list']);
		}

onSubmit(categoryData:any){
	if(categoryData.valid){
		this.submitted=true;
		this.categoryService.updateCategory(this.id, categoryData.value);
		this.router.navigate(['category-list']);

	}

}

}
