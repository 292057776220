<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
        <span class="nav-inside"><a href="dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
        <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>
<div class="container">
<div class="row">
    <div class="col-md-12 form-group searchBox">
      <input type="text" [(ngModel)]="searchBox" class="form-control" placeholder=" ... Пребарај ги истражувачите ..." style="display: inline;width: 89%" />
      <span (click)="search(searchBox)" class="btn btn-primary">Пребарај </span>
    </div>
  </div>
	<div style="text-align: right;padding: 20px 0">
		<a href="/researcher-add" class="btn btn-primary">Додади нов истражувач</a>
	</div>
	<div class="list_grid list_grid_title">
		<div class="row">
			<div class="col-md-8">Листа на истражувачи</div>
			<div class="col-md-2" ><fa-icon [icon]="editIcon"></fa-icon></div>
			<div class="col-md-2"><fa-icon [icon]="trashIcon"></fa-icon></div>
		</div>
	</div>
	<div *ngFor="let researcher of researchers" class="list_grid">
		<div class="row">
			<div class="col-md-8">{{researcher.name}}</div>
			<div class="col-md-2"><a  routerLink="{{researcher.id}}">Промени</a></div>
			<div class="col-md-2 del"(click)="deleteResearcher(researcher.id)">Избриши</div>
		</div>
	</div>
	 <div class="row prev_next">
    <div class="col-md-6"><button class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButton" (click)="prevResearcherPage()">Претходна страница</button></div>
    <div class="col-md-6"><button class="btn btn-primary btn-sm float-right" (click)="nextResearcherPage()" [disabled]="disableNextButton">Следна страница</button></div>
  </div>
</div>