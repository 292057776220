import { Component, OnInit } from '@angular/core';
import {ResearcherService} from '../../shared/services/researcher.service';
import { map } from 'rxjs/operators';
import {Router, ActivatedRoute} from "@angular/router";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';


@Component({
	selector: 'app-dashboard-researcher-list',
	templateUrl: './dashboard-researcher-list.component.html',
	styleUrls: ['./dashboard-researcher-list.component.css']
})
export class DashboardResearcherListComponent implements OnInit {
	editIcon=faEdit;
	trashIcon=faTrash;
	researchers:any;
	disableNextButton:any;
	disablePrevButton=true;
	firstResearcher:any;
	mainFirstResearcher:any;
	lastResearcher:any;
	searchBox:any;
	constructor(private researcherService:ResearcherService, private router:Router) { }

	ngOnInit(): void {
		this.getAllResearchers();
	}
	getAllResearchers(){
		let listener = this.researcherService.getAllResearchers().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))).subscribe(data=>{
			this.researchers=data;
			this.firstResearcher=data[0];
			this.mainFirstResearcher=data[0];
			this.lastResearcher=data[data.length-1];

			//check for next item
			if(this.researchers.length==10){
			var myNextlistener=this.researcherService.nextResearcherDataOne(this.lastResearcher).snapshotChanges().subscribe(a=>{
				if(a.length!=0){
					this.disableNextButton=false;
				}else{
					this.disableNextButton=true;
				}
				myNextlistener.unsubscribe();
			})
		}else{
			this.disableNextButton=true;
		}

			listener.unsubscribe();
		});
		}

		nextResearcherPage(){

			
			let listener=this.researcherService.nextResearcherData(this.lastResearcher).snapshotChanges().pipe(
				map(actions => actions.map(a => {
					const data = a.payload.doc.data();
					const id = a.payload.doc.id;
					return { id, ...data };
				}))
				).subscribe(data => {
					this.disableNextButton = true;
					var removeCount = 0;
					if (data.length == 11) {
						removeCount = 1;
						this.disableNextButton = false;
					}
					var newArrayResearcher = [];
					for(let i=0;i<data.length-removeCount;i++){
						newArrayResearcher.push(data[i]);	
					}

					this.researchers = newArrayResearcher;
					this.firstResearcher=newArrayResearcher[0];
					this.lastResearcher=newArrayResearcher[newArrayResearcher.length-1];

					this.disablePrevButton=false;
					listener.unsubscribe();
				});

			}

			prevResearcherPage(){
				let listener=this.researcherService.prevResearcherData(this.firstResearcher).snapshotChanges().pipe(
					map(actions => actions.map(a => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					}))
					).subscribe(data => {
						this.researchers = data;
						this.firstResearcher=data[0];
						this.lastResearcher=data[data.length-1];
						this.disableNextButton=false;
						if(this.mainFirstResearcher.title===this.firstResearcher.title){
							this.disablePrevButton=true;
						}
						listener.unsubscribe();
					});
				}

				search(name:any){
					let listener = this.researcherService.searchResearcher(name).snapshotChanges().pipe(
						map(actions => actions.map(a => {
							const data = a.payload.doc.data();
							const id = a.payload.doc.id;
							return { id, ...data };
						}))).subscribe(data=>{
						this.researchers=data;
						listener.unsubscribe();
					});
						if(name==''){
							this.getAllResearchers();
						}
					}

					deleteResearcher(id:any){
						this.researcherService.deleteResearcher(id);
						let currentUrl = this.router.url;
						this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
							this.router.navigate([currentUrl]);
						});
					}
				}