<app-frontend-header></app-frontend-header>
<div class="container category">
	<div class="row" *ngFor="let cat of categoryData">
	<div class="col-md-12">
		<h3 class="main-title">{{cat.name}}</h3></div>
		<div [innerHTML]="cat.description" class="col-12 col-md-12 textjustify">
		</div>
	</div>
	<div class="row" >
		<div class="col-6 col-md-3">
		<div class="block">
			<a routerLink="/category/{{catName}}/research-list/" class="catCircle">
				<span>Истражувања</span>
			</a>
			</div>
		</div>
		<div class=" col-6 col-md-3">
		<div class="block">
			<a routerLink="/category/{{catName}}/graph-list/" class="catCircle">
				<span>Графици</span>
			</a>
			</div>
		</div><div class="col-6 col-md-3">
		<div class="block">
			<a routerLink="/category/{{catName}}/infographic-list/" class="catCircle">
				<span>Инфографици</span>
			</a>
			</div>
		</div>
	</div>
</div>
<app-frontend-footer></app-frontend-footer>
