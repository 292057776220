import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { PageService } from 'src/app/shared/services/page.service';
import {Router, ActivatedRoute} from "@angular/router";
import { faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-frontend-page',
  templateUrl: './frontend-page.component.html',
  styleUrls: ['./frontend-page.component.css']
})
export class FrontendPageComponent implements OnInit {
id:any;
pageData:any;
currentL:any;
envelope=faEnvelope;
  constructor(private fb:FormBuilder, private staticpage:PageService, private rout:ActivatedRoute, private router:Router) { }
	ngOnInit(): void {
		this.id = this.rout.snapshot.paramMap.get('page');
		let staticpageListener=this.staticpage.getPageData(this.id).subscribe(a=>{
			this.pageData=a;
			staticpageListener.unsubscribe();
		});
		
		    
	}



}
