<app-frontend-header></app-frontend-header>
<div class="container research">
  <div class="row">
    <div class="col-md-12">
      <h3 class="main-title">Сите</h3>
      <h4 class="main-title">Графици</h4>
    </div>
  </div>
  <div class="breadcrumbs">
    <a href="/"><span>Почетна</span></a>
    <a href="/graph-all"><span>Сите графици</span></a>
  </div>
  <div class="row">
    <div class="col-md-9">
      <div class="row" *ngFor="let graph of graphList; let i=index ">
        <div class="col-md-3">
          <a href="/category/{{firstCategory}}/graph-list/{{graph?.id}}" class="boxI">
            <div class="boxImage" style="background-image:url('{{graph?.image_path}}')"></div>
          </a>
        </div>
        <div class="col-md-9">
          <div>
            <h4><a href="/category/{{firstCategory}}/graph-list/{{graph?.id}}">{{graph?.title}}</a></h4>
          </div>
          <span class="desc_" [innerHtml]="graph?.description.slice(0,300)"> </span><span class="more"><a href="/category/{{firstCategory}}/graph-list/{{graph?.id}}">...Прочитајте повеќе</a></span>
        </div>
      </div>
      <div class="row prev_next">
        <div class="col-6"><button class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButton" (click)="prevGraphPage()">Претходна страница</button></div>
        <div class="col-6"><button class="btn btn-primary btn-sm float-right" (click)="nextGraphPage()" [disabled]="disableNextButton">Следна страница</button></div>
      </div>
    </div>
    <div class="col-md-3">
      <app-frontend-sidebar></app-frontend-sidebar>
    </div>
  </div>
</div>
<app-frontend-footer></app-frontend-footer>
