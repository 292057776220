<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
    <span class="nav-inside"><a href="/dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
        <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>
<div class="container">
  <div class="dashboard_title">Ажурирај категорија</div>
  <form #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm)">
    <div class="form-group">
      <label for="categoryName">Име</label>
      <input type="text" class="form-control" id="categoryName" name="name" [(ngModel)]="category.name" #name="ngModel" aria-describedby="emailHelp" placeholder="" [ngClass]="{ 'is-invalid': categoryForm.submitted && name.invalid }" required>
    </div>
    <div class="form-group">
      <label for="categoryDesc">Опис</label>
      <angular-editor [placeholder]="'Внесете опис'" [(ngModel)]="category.description" name="description" #description="ngModel" [ngClass]="{ 'is-invalid':  categoryForm.submitted && description.invalid }" required [config]="config"></angular-editor>
    </div>
    <div class="container" style="margin-bottom: 50px">
      <div class="row">
        <div class="col-md-9">
          <button type="submit" class="btn btn-primary">Ажурирај</button>
        </div>
        <div class="col-md-3">
          <button class="btn btn-primary" (click)="deleteCategory()">Избриши (веднаш)</button>
        </div>
      </div>
    </div>
  </form>
</div>
