<div class="container-md">
<div class="logo">
	<a href="/" style="display: block;height: 100%;width: 100%"></a>
</div>
	<nav class="navbar navbar-expand-lg navbar-light">
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation" data-bs-toggle="collapse"  data-bs-target="#collapsibleNavbar">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarColor03">
			<ul class="nav mx-auto" style="align-items: center;justify-content: center;">
				<li class="nav-item">
					<a class="nav-link active" aria-current="page" href="/">Почетна</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/page/about-page">За страницата</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/page/about-us">За нас</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/page/contact">Контакт</a>
				</li>
				<li style="display: block;width: 100%;text-align: center;">
				<div>
					<input type="text" [(ngModel)]="searchBox" class="form-control" style="display: inline;width: 89%" placeholder=" ... Пребарај ... " />
					<span  class="my_btn" (click)="search(searchBox)">
					<span></span>
					</span> 
					</div>
					</li>

				</ul>

			</div>

		</nav>
		<div class="right_img">
			<div class="media-links">
                    <a href="https://www.facebook.com/pages/%D0%A0%D0%BE%D0%B4%D0%BE%D0%B2-%D0%A0%D0%B5%D0%B0%D0%BA%D1%82%D0%BE%D1%80/224937357649692?ref=ts&amp;fref=ts" target="_blank" class="fb"><span></span></a>
                    <a href="https://twitter.com/rodovreaktor" target="_blank" class="tw"><span></span></a>
                    <a href="https://plusone.google.com/_/+1/confirm?hl=en&amp;url=http://rodovreactor.mk" target="_blank" class="gp"><span></span></a>
                    <a href="#" target="_blank" class="gh"><span></span></a>
          </div>
		</div>

	</div>
