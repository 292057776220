<app-frontend-header></app-frontend-header>
<div class="container research">
  <div class="row">
    <div class="col-md-12">
      <h3 class="main-title">{{category}}</h3>
      <h4 class="main-title">Истражувања</h4>
    </div>
  </div>
  <div class="breadcrumbs">
    <a href="/"><span>Почетна</span></a>
    <a routerLink="/category/{{category}}/research-list"><span>{{category}}</span></a>
  </div>
  <div class="row">
    <div class="col-md-9">
      <div class="row" *ngFor="let research of researchList">
        <div class="col-md-3">
        <a href="/category/{{category}}/research-list/{{research.id}}" class="boxI">
          <div class="boxImage" style="background-image:url('{{research?.image_path}}')">
          </div>
          </a>
        </div>
        <div class="col-md-9">
              <div *ngIf="emptyResearch">Моментално категоријата е празна.</div>
          <div>
            <h4><a href="/category/{{category}}/research-list/{{research.id}}">{{research.title}}</a></h4>
          </div>
          <span class="desc_" [innerHtml]="research?.abstract.slice(0,300)"> </span><span class="more"><a href="/category/{{category}}/research-list/{{research.id}}">...Прочитајте повеќе</a></span>
        </div>
      </div>
      <div class="row prev_next">
        <div class="col-6"><button class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButton" (click)="prevResearchPage()">Претходна страница</button></div>
        <div class="col-6"><button class="btn btn-primary btn-sm float-right" (click)="nextResearchPage()" [disabled]="disableNextButton">Следна страница</button></div>
      </div>
    </div>
    <div class="col-md-3">
      <app-frontend-sidebar [category]="category" [selectType]="selectType"></app-frontend-sidebar>
    </div>
  </div>
  <app-frontend-footer></app-frontend-footer>
