import { Component, OnInit } from '@angular/core';
import {CategoryService} from '../../shared/services/category.service';
import {Category} from '../../shared/models/category';
import {Router, ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-frontend-category-page',
  templateUrl: './frontend-category-page.component.html',
  styleUrls: ['./frontend-category-page.component.css']
})
export class FrontendCategoryPageComponent implements OnInit {

  constructor( private categoryService:CategoryService, private rout:ActivatedRoute) {
  }
  name:any;
  categoryData:any;
  catName:any;
  ngOnInit(): void {
    this.name = this.rout.snapshot.paramMap.get('category');
    var listener=this.categoryService.getCategoryDataByName(this.name).valueChanges().subscribe(cat=>{
      this.categoryData=cat;
      this.catName=cat[0].name;
      listener.unsubscribe();
    })

  }

}
