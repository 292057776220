import { Component, OnInit} from '@angular/core';
import {ResearcherService} from '../../shared/services/researcher.service';
import { Router } from "@angular/router";

import {Researcher} from '../../shared/models/researcher';
@Component({
	selector: 'app-dashboard-researcher-add',
	templateUrl: './dashboard-researcher-add.component.html',
	styleUrls: ['./dashboard-researcher-add.component.css']
})
export class DashboardResearcherAddComponent implements OnInit {
	submitted=false;
	researcher={} as Researcher;
	constructor(public researcherService:ResearcherService, private router:Router) { }
	ngOnInit(): void {
	}

	onSubmit(researcherData:any){
		if(researcherData.valid){
			this.submitted=true;
			this.researcherService.createResearcher(researcherData.value);
			this.router.navigate(['/researcher-list']);

		}
		
	}

}
