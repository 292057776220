<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
    <span class="nav-inside"><a href="/dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
        <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-8 dashboard_title">Категории</div>
    <div class="col-md-4" style="text-align: right;padding: 20px 0">
      <a href="/category-add" class="btn btn-primary">Додади нова категорија</a>
    </div>
  </div>
  <div class="list_grid list_grid_title">
    <div class="row">
      <div class="col-md-8">Листа на категории</div>
      <div class="col-md-2">
        <fa-icon [icon]="editIcon"></fa-icon>
      </div>
      <div class="col-md-2">
        <fa-icon [icon]="trashIcon"></fa-icon>
      </div>
    </div>
  </div>
  <div *ngFor="let category of categories" class="list_grid">
    <div class="row">
      <div class="col-md-8"><a routerLink="{{category.id}}">{{category.name}}</a></div>
      <div class="col-md-2"><a routerLink="{{category.id}}">Промени</a></div>
      <div class="col-md-2 del"><div (click)="deleteCategory(category.id)">Избриши</div></div>
    </div>
  </div>
</div>
