<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
        <span class="nav-inside"><a href="dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
        <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>

	<div class="container">
	<div class="dashboard_title">Страница: {{page.title}}</div>
		<form #pageForm="ngForm" (ngSubmit)="onSubmit(pageForm)">
			<div class="form-group">
				<label for="pageName">Име</label>
				<input type="text" class="form-control" id="pageName" name="title" 
				[(ngModel)]="page.title" #title="ngModel" aria-describedby="emailHelp" placeholder="Внесете име" [ngClass]="{ 'is-invalid': pageForm.submitted && title.invalid }" required>
			</div>
			<div class="form-group">
				<label for="description">Опис</label>
				<angular-editor [placeholder]="'Внесете опис'" [(ngModel)]="page.description" name="description" #description="ngModel" [ngClass]="{ 'is-invalid':  pageForm.submitted && description.invalid }" required [config]="config"></angular-editor>
			</div>
			<button type="submit" class="btn btn-primary">Потврди</button>
		</form>
	</div>