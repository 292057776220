import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import {Research} from '../models/research';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ResearchService {
	catList:any;
	filterCategory:any;
	filterResearch:any;
	researchList:any;
	private dBresearches='/researches';
	researchesRef:AngularFirestoreCollection<Research>;
	researchesRefForGraph:AngularFirestoreCollection<Research>;

	constructor(private db:AngularFirestore) {
		this.researchesRef=db.collection(this.dBresearches,ref => ref.orderBy('date','desc').limit(10));
		this.researchesRefForGraph=db.collection(this.dBresearches,ref => ref.orderBy('title','asc'));
	}

	createResearch(research:Research):any{
		return this.researchesRef.add({...research}).then(() => {
			console.log("Document successfully written!");
		})
		.catch((error) => {
			console.error("Error writing document: ", error);
		});
	}


	getAllResearchesForGraph(){
		return this.researchesRefForGraph;
	}


	// getOneResearchForGraph(name:any){
	// return this.db.collection(this.dBresearches,ref=>ref.where('title','==',name));


	// }

	

	async getOneResearchForGraph(name:any){
		var researchTitle=this.db.collection(this.dBresearches,ref => ref.where('title', "==", name));
		return await new Promise((resolve, reject) => {
			let filterCatListListener=researchTitle.snapshotChanges().subscribe((data:any) => {
				var id = data.map((e:any) => {

					return {
						...{id:e.payload.doc.id}
					};
				});
				resolve(id);
				filterCatListListener.unsubscribe();
			});
		});
	}

	getAllResearches(){
		return this.researchesRef;
	}
	async getAsyncResearches(){
		return await new Promise((resolve:any, reject:any) => {
			var getRes:any;
			let getResListener=this.researchesRef.valueChanges().subscribe(data => {
				getRes=data;
				resolve(getRes);
				getResListener.unsubscribe();
			});
		});
	}

	getResearchData(id:any){
		return this.researchesRef.doc(id);
	}
	updateResearch(id:any, data:any){
		return this.researchesRef.doc(id).update(data);
	}
	deleteResearch(id:any){
		return this.researchesRef.doc(id).delete();
	}


	searchResearch(title:any):AngularFirestoreCollection<Research>{
		const end = title.replace(/.$/, (c:any) => String.fromCharCode(c.charCodeAt(0) + 1));			
		return this.db.collection(this.dBresearches, ref=>ref.where('title', '>=', title)
			.where('title', '<', end).limit(10));		

	}


	nextResearchDataOne(next:any):AngularFirestoreCollection<Research>{
		return this.db.collection(this.dBresearches,ref => ref.orderBy('date','desc').startAfter(next['date']).limit(1));
	}

	nextResearchData(next:any):AngularFirestoreCollection<Research>{
		return this.db.collection(this.dBresearches,ref => ref.orderBy('date','desc').startAfter(next['date']).limit(11));
	}

	prevResearchData(prev:any):AngularFirestoreCollection<Research>{
		return this.db.collection(this.dBresearches,ref => ref.orderBy('date', 'desc').endBefore(prev['date']).limitToLast(10));
	}

	async filterCategoryList(id:any){
		this.filterCategory=this.db.collection(this.dBresearches,ref => ref.where("categories", "array-contains",  { ischecked:true, name : id}).orderBy('date','desc'));
		return await new Promise((resolve, reject) => {
			let filterCatListListener=this.filterCategory.snapshotChanges().subscribe((data:any) => {
				this.catList = data.map((e:any) => {
					return {
						...{id:e.payload.doc.id, data:e.payload.doc.data()}
					};
				});
				resolve(this.catList);
				filterCatListListener.unsubscribe();
			});
		});
	}




	filterCategoryNext(id:any, next:any):AngularFirestoreCollection<Research>{
		console.log(next.data.date);
		return 	this.db.collection(this.dBresearches,ref => ref.where("categories", "array-contains",  { ischecked:true, name : id}).orderBy('date','desc').startAfter(next['date']).limit(11));

	}

	filterCategoryNextOne(id:any,next:any):AngularFirestoreCollection<Research>{
		return 	this.db.collection(this.dBresearches,ref => ref.where("categories", "array-contains",  { ischecked:true, name : id}).orderBy('date','desc').startAfter(next['date']).limit(1));

	}

	filterCategoryPrev(id:any,next:any):AngularFirestoreCollection<Research>{
		return 	this.db.collection(this.dBresearches,ref => ref.where("categories", "array-contains",  { ischecked:true, name : id}).orderBy('date','desc').endBefore('date').limitToLast(10));

	}
	getResearchDataByName(name:any){
		return this.db.collection(this.dBresearches,ref => ref.where('__name__','==',name));
	}
	// async getResearchDataByGraphName(name:any){
		// 	this.filterResearch=this.db.collection(this.dBresearches,ref => ref.where("researches", "array-contains",  { ischecked:true, name : name}));
		// 	return await new Promise((resolve, reject) => {
			// 		let filterResearchListListener=this.filterResearch.snapshotChanges().subscribe((data:any) => {
				// 			this.researchList = data.map((e:any) => {
					// 				return {
						// 					...{id:e.payload.doc.id, data:e.payload.doc.data()}
						// 				};
						// 			});
						// 			console.log(this.researchList);
						// 			resolve(this.researchList);
						// 			filterResearchListListener.unsubscribe();
						// 		});
						// 	});
						// }
					}