import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {ResearchService} from '../../shared/services/research.service';
import {Research} from '../../shared/models/research';
import {GraphService} from '../../shared/services/graph.service';
@Component({
	selector: 'app-frontend-research-page',
	templateUrl: './frontend-research-page.component.html',
	styleUrls: ['./frontend-research-page.component.css']
})
export class FrontendResearchPageComponent implements OnInit {

	category:any;
	id:any;
	researchData:any;
	graphData:any;
	selectType='истражувања';
	constructor(private rout:ActivatedRoute, private researchService:ResearchService, private graphService:GraphService) { }
	async ngOnInit(){
		this.category = this.rout.snapshot.paramMap.get('category');
		this.id=this.rout.snapshot.paramMap.get('id');
		var listener=this.researchService.getResearchDataByName(this.id).valueChanges().subscribe(research=>{
			this.researchData=research;
			for(let res of this.researchData){
			this.getDataViz(res.title)

			}
			listener.unsubscribe();
		});

	}


	async getDataViz(name:any){
		this.graphData=await this.graphService.getGraphDataByResearchName(name);
	}




}
