import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";

//import * as $ from 'jquery' 

@Component({
	selector: 'app-frontend-header',
	templateUrl: './frontend-header.component.html',
	styleUrls: ['./frontend-header.component.css']
})
export class FrontendHeaderComponent implements OnInit {
	searchBox:any;
	parm:any;
	constructor(private router:Router, private rout:ActivatedRoute) { }
	ngOnInit(): void {
		this.parm = this.rout.snapshot.paramMap.get('searchtext');
		if(this.parm){
			this.searchBox=this.parm;
		}
	}

	search(search:any){
		if(search==undefined){
			return;
		}else{
			this.searchBox=search;
		this.router.navigate(['search/'+search]);
		this.reloadPage();
		}
		
	}
	reloadPage(){
			    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		}

}
