<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
        <span class="nav-inside"><a href="dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
        <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>

	<div class="container">
	<div class="dashboard_title">Додади нов истражувач</div>
		<form #researchForm="ngForm" (ngSubmit)="onSubmit(researchForm)">
			<div class="form-group">
				<label for="researcherName">Име и презиме</label>
				<input type="text" class="form-control" id="reseracherName" name="name" 
				[(ngModel)]="researcher.name" #name="ngModel" aria-describedby="emailHelp" placeholder="Внесете име" [ngClass]="{ 'is-invalid': researchForm.submitted && name.invalid }" required>
			</div>
			<div class="form-group">
				<label for="researcherLink">Линк</label>
				<input type="text" class="form-control" id="reseracherLink" name="link" 
				[(ngModel)]="researcher.link" #link="ngModel" placeholder="Внесете линк или раб. место" 
				[ngClass]="{ 'is-invalid': researchForm.submitted && link.invalid }" required>
				
			</div>
			<button type="submit" class="btn btn-primary">Додадете истражувач</button>
		</form>
	</div>