<app-dashboard-header></app-dashboard-header>
<div style="background-color: #f9cf888a">
  <div class="container">
    <span class="nav-inside"><a href="dashboard">Почетна</a></span>
    <span class="nav-inside"><a href="category-list">Категории</a></span>
        <span class="nav-inside"><a href="researcher-list">Истажувачи</a></span>
    <span class="nav-inside"><a href="research-list">Истражувања</a></span>
    <span class="nav-inside"><a href="graph-list">Графици - инфографици</a></span>
  </div>
</div>
<div class="container">
  <div class="dashboard_title">Ажурирај истражувач</div>
  <form #researchForm="ngForm" (ngSubmit)="onSubmit(researchForm)">
    <div class="form-group">
      <label for="researcherName">Име</label>
      <input type="text" class="form-control" id="reseracherName" name="name" [(ngModel)]="researcher.name" #name="ngModel" aria-describedby="emailHelp" placeholder="Enter researcher name" [ngClass]="{ 'is-invalid': researchForm.submitted && name.invalid }" required>
    </div>
    <div class="form-group">
      <label for="researcherLink">Линк</label>
      <input type="text" class="form-control" id="reseracherLink" name="link" [(ngModel)]="researcher.link" #link="ngModel" placeholder="Enter researcher Link" [ngClass]="{ 'is-invalid': researchForm.submitted && link.invalid }" required>
    </div>
    <div class="container" style="margin-bottom: 50px">
      <div class="row">
        <div class="col-md-9">
          <button type="submit" class="btn btn-primary">Ажурирај</button>
        </div>
        <div class="col-md-3">
          <button class="btn btn-primary" (click)="deleteResearcher()">Избриши (веднаш)</button>
        </div>
      </div>
    </div>
  </form>
</div>
