<app-frontend-header></app-frontend-header>
<div class="container">
  <h3 class="main-title">Со информирани ставови до еднаквост!</h3>
  <div class="row home">
    <div class="col-6 col-sm-4 col-md-4">
      <div id="politics" class="block">
        <a routerLink="/category/Родот и политиката" class="homeCircle">
          <span>Политика</span>
        </a>
        <div class="icon"></div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-md-4">
      <div id="family" class="block">
       <a routerLink="/category/Родот и семејството" class="homeCircle">
          <span>Семејство</span>
        </a>
        <div class="icon"></div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-md-4">
      <div id="economy" class="block">
       <a routerLink="/category/Родот и економијата" class="homeCircle">
          <span>Економија</span>
        </a>
        <div class="icon"></div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-md-4">
      <div id="youth" class="block">
        <a routerLink="/category/Родот и младите" class="homeCircle">
          <span>Млади</span>
        </a>
        <div class="icon"></div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-md-4">
      <div id="urban" class="block">
        <a routerLink="/category/Родот и јавните простори" class="homeCircle">
          <span>Јавни<br> простори</span>
        </a>
        <div class="icon"></div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-md-4">
      <div id="health" class="block">
       <a routerLink="/category/Родот и здравјето" class="homeCircle">
          <span>Здравје</span>
        </a>
        <div class="icon"></div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-md-4">
      <div id="education" class="block">
       <a routerLink="/category/Родот и образованието" class="homeCircle">
          <span>Образование</span>
        </a>
        <div class="icon"></div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-md-4">
      <div id="social_welfare" class="block">
       <a routerLink="/category/Родот и социјалната политика" class="homeCircle">
          <span>Социјална <br> политика</span>
        </a>
        <div class="icon"></div>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-md-4">
      <div id="other" class="block">
       <a routerLink="/category/Останати графици и истражувања" class="homeCircle">
          <span>Останати</span>
        </a>
        <div class="icon"></div>
      </div>
    </div>
  </div>
  <div class="view-all col-md-12">
    <a href="/research-all/">Сите истражувања</a> | <a href="/graph-all/"> Сите графици</a> | <a href="/infographic-all/"> Сите инфографици</a>
  </div>
</div>
<app-frontend-footer></app-frontend-footer>
