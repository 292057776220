import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { map } from 'rxjs/operators';

import {GraphService} from '../../shared/services/graph.service';
import {Graph} from '../../shared/models/graph';
@Component({
	selector: 'app-frontend-graph-all',
	templateUrl: './frontend-graph-all.component.html',
	styleUrls: ['./frontend-graph-all.component.css']
})
export class FrontendGraphAllComponent implements OnInit {

	constructor(private rout:ActivatedRoute, private graphService:GraphService) { }
	graphList:any;
	firstGraph:any;
	mainFirstGraph:any;
	lastGraph:any;
	disableNextButton:any;
	disablePrevButton=true;
	selectType:any;
	firstCategory:any;

	ngOnInit(){
		this.getAllGraph()
	}

	getAllGraph(){


		var lis=this.graphService.getGraphsAll().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))).subscribe(allgraphs=>{
			this.graphList=allgraphs;
			this.selectType=allgraphs[0].graphic_type_view;

			this.firstGraph=this.graphList[0];
			this.mainFirstGraph=this.graphList[0];
			this.lastGraph=this.graphList[this.graphList.length-1];


			//first category true for the url

			for(var cat of allgraphs){
				console.log(cat.categories);
				for(var c of cat.categories){
					if(c.ischecked==true){
					this.firstCategory=c.name;}

				}
				
			}

			//check for next item
			var myNextlistener=this.graphService.nextGraphDataOne(this.lastGraph).snapshotChanges().subscribe(a=>{
				if(a.length!=0){
					this.disableNextButton=false;
				}else{
					this.disableNextButton=true;
				}
				myNextlistener.unsubscribe();
			});

			lis.unsubscribe();


		})


		}

		nextGraphPage(){
			
			let listener=this.graphService.nextGraphData(this.lastGraph).snapshotChanges().pipe(
				map(actions => actions.map((a:any) => {
					const data = a.payload.doc.data();
					const id = a.payload.doc.id;
					return { id, ...data };
				}))
				).subscribe(data => {
					this.disableNextButton = true;
					var removeCount = 0;
					if (data.length == 11) {
						removeCount = 1;
						this.disableNextButton = false;
					}
					var newArrayResearcher = [];
					for(let i=0;i<data.length-removeCount;i++){
						newArrayResearcher.push(data[i]);	
					}
					this.graphList = newArrayResearcher;
					this.firstGraph=newArrayResearcher[0];
					this.lastGraph=newArrayResearcher[newArrayResearcher.length-1];

					this.disablePrevButton=false;
					listener.unsubscribe();
				});

			}

			prevGraphPage(){
				let listener=this.graphService.prevGraphData(this.firstGraph).snapshotChanges().pipe(
					map(actions => actions.map((a:any) => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					}))
					).subscribe(data => {
						this.graphList = data;
						this.firstGraph=data[0];
						this.lastGraph=data[data.length-1];
						this.disableNextButton=false;
						if(this.mainFirstGraph.title===this.firstGraph.title){
							this.disablePrevButton=true;
						}
						listener.unsubscribe();
					});
				}


			}
