import { Component, OnInit } from '@angular/core';
import {PageService} from '../../shared/services/page.service';
import { Router, ActivatedRoute } from "@angular/router";
import {Page} from '../../shared/models/page';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css']
})
export class DashboardPageComponent implements OnInit {
submitted=false;
	page={} as Page;
id:any;
	constructor(public pageService:PageService, private router:Router, private rout:ActivatedRoute) { }
	ngOnInit(): void {
		this.id = this.rout.snapshot.paramMap.get('page');
let staticListener=this.pageService.getPageData(this.id).subscribe(data=>{
			this.page={title:data?.title!,description:data?.description!};
			staticListener.unsubscribe();
		});
	}
	

	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "200px",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "p",
		defaultFontName: "PT Sans",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: false,
		customClasses: [
		
		{
			name: "centerTextImage",
			class: "centerTextImage",
		},
		{	name:"article_img",
			class:"article_img"
		},{
			name:'title',
			class:'main-title'
		}, {
			name:'envelope',
			class:'envelope'
		},{
			name:"weblink",
			class:"weblink"
		},{
			name:"fbicon",
			class:"fbicon"
		},{
			name:"twicon",
			class:'twicon'
		}],
	 // uploadUrl: '',
	 //    uploadWithCredentials: false,	

	};

	onSubmit(pageData:any){
		if(pageData.valid){
			this.submitted=true;
			this.pageService.updatePage(this.id,pageData.value);
			this.router.navigate(['dashboard']);
		}
		
	}


}
