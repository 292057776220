import { Component, OnInit } from '@angular/core';
import {GraphService} from '../../shared/services/graph.service';
import { map } from 'rxjs/operators';
import {Router, ActivatedRoute} from "@angular/router";
import { faEdit, faTrash, faChartBar } from '@fortawesome/free-solid-svg-icons';
@Component({
	selector: 'app-dashboard-graph-list',
	templateUrl: './dashboard-graph-list.component.html',
	styleUrls: ['./dashboard-graph-list.component.css']
})
export class DashboardGraphListComponent implements OnInit {
	editIcon=faEdit;
	trashIcon=faTrash;
	chartIcon=faChartBar;
	graphs:any;
	disableNextButton:any;
	disablePrevButton=true;
	firstGraph:any;
	mainFirstGraph:any;
	lastGraph:any;
	searchBox:any;
	constructor(private graphService:GraphService, private router:Router) { }

	ngOnInit(): void {
		this.getAllGraphs();
	}
	getAllGraphs(){
		let listener = this.graphService.getAllGraphs().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))).subscribe(data=>{
			this.graphs=data;
			this.firstGraph=data[0];
			this.mainFirstGraph=data[0];
			this.lastGraph=data[data.length-1];

			//check for next item
			var myNextlistener=this.graphService.nextGraphAllDataOne(this.lastGraph).snapshotChanges().subscribe(a=>{
				if(a){
					this.disableNextButton=false;
				}else{
					this.disableNextButton=true;
				}
				myNextlistener.unsubscribe();
			})


			listener.unsubscribe();
		});
			

		}



		nextGraphPage(){
			
			let listener=this.graphService.nextGraphAllData(this.lastGraph).snapshotChanges().pipe(
				map(actions => actions.map(a => {
					const data = a.payload.doc.data();
					const id = a.payload.doc.id;
					return { id, ...data };
				}))
				).subscribe(data => {
					this.disableNextButton = true;
					var removeCount = 0;
					if (data.length == 11) {
						removeCount = 1;
						this.disableNextButton = false;
					}
					var newArrayGraph = [];
					for(let i=0;i<data.length-removeCount;i++){
						newArrayGraph.push(data[i]);	
					}
					this.graphs = newArrayGraph;
					this.firstGraph=newArrayGraph[0];
					this.lastGraph=newArrayGraph[newArrayGraph.length-1];

					this.disablePrevButton=false;
					listener.unsubscribe();
				});

			}

			prevGraphPage(){
				let listener=this.graphService.prevGraphAllData(this.firstGraph).snapshotChanges().pipe(
					map(actions => actions.map(a => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					}))
					).subscribe(data => {
						this.graphs = data;
						this.firstGraph=data[0];
						this.lastGraph=data[data.length-1];
						this.disableNextButton=false;
						if(this.mainFirstGraph.title===this.firstGraph.title){
							this.disablePrevButton=true;
						}
						listener.unsubscribe();
					});
				}

				search(name:any){
					let listener = this.graphService.searchGraphAll(name).snapshotChanges().pipe(
						map(actions => actions.map(a => {
							const data = a.payload.doc.data();
							const id = a.payload.doc.id;
							return { id, ...data };
						}))).subscribe(data=>{
						this.graphs=data;
						listener.unsubscribe();
					});
						if(name==''){
							this.getAllGraphs();
						}
					}



					deleteGraph(id:any){
						this.graphService.deleteGraph(id);
						let currentUrl = this.router.url;
						this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
							this.router.navigate([currentUrl]);
						});
					}


				}
